import { createRouter, createWebHistory } from 'vue-router';
import NewUser from '../components/NewUser.vue';
import Quiz from '../components/Quiz.vue';
import Profile from '../views/profile.vue';
import Login from '../components/LoginForm.vue';
import SignUp from '../components/SignupForm.vue';
import quizAbout from '../views/quiz1_1.vue';
import AboutFamily from '../views/quiz1_2.vue';
import executors from '../views/quiz3.vue';
import Assets from '../views/quiz2.vue';
import mastros from '../views/mastros.vue';
import quiz4 from '../views/quiz4.vue';
import quiz5 from '../views/quiz5.vue';
import VerifyEmail from '../views/VerifyEmail.vue';
import { auth } from '../firebase/config';
import Home from "../views/HomeView.vue";
import Cookies from '../views/Cookies.vue';
import PrivacyPolicy from '../views/PrivacyPolicy.vue';
import TermsConditions from '../views/TermsConditions.vue';

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: Home
  },
  { 
    path: '/new-user',
    name: 'new-user',
    component: NewUser
  },
  {
    path: '/Quiz',
    name: 'Quiz',
    component: Quiz
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  { 
    path: '/AboutYou',
    name: 'quizAbout',
    component: quizAbout,
    meta: {
      requiresAuth: true  
    }
  },
  { 
    path: '/AboutFamily',
    name: 'AboutFamily',
    component: AboutFamily,
    meta: {
      requiresAuth: true  
    }
  },
  { 
    path: '/Assets',
    name: 'Assets',
    component: Assets,
    meta: {
      requiresAuth: true  
    }
  },
  { 
    path: '/mastros',
    name: 'mastros',
    component: mastros,
    meta: {
      requiresAuth: true  
    }
  },
  {
    path: '/executors',
    name: 'executors',
    component: executors,
    meta: {
      requiresAuth: true  
    }
  },
  {
    path: '/wishes-and-gifts',
    name: 'quiz4',
    component: quiz4,
    meta: {
      requiresAuth: true  
    }
  },
  {
    path: '/personal-statement',
    name: 'quiz5',
    component: quiz5,
    meta: {
      requiresAuth: true  
    }
  },
  {
    path: '/login', 
    name: 'login',
    component: Login
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignUp
  },
  {
    path: '/MyProfile', 
    name: 'profile',
    component: Profile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/verify-email',
    name: 'VerifyEmail',
    component: VerifyEmail
  },
  { path: '/cookies', name: 'Cookies', component: Cookies },
  { path: '/privacy', name: 'PrivacyPolicy', component: PrivacyPolicy },
  { path: '/terms', name: 'TermsConditions', component: TermsConditions },
  {
    path: '/contact',
    name: 'ContactSection',
    component: Home,
    beforeEnter: (to, from, next) => {
      next({ name: 'HomeView', hash: '#contact' });
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const user = auth.currentUser;

  if (user) {
    user.reload().then(() => {
      if (!user.emailVerified && to.path !== '/verify-email') {
        next('/verify-email');
      } else if (requiresAuth && user.emailVerified) {
        next();
      } else {
        next();
      }
    }).catch(() => {
      next('/login');
    });
  } else if (requiresAuth) {
    next('/login');
  } else {
    next();
  }
});

export default router;
