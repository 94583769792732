<template>
  <div class="content-container">
    <header>
      <h2>Admin Console</h2>
    </header>
    <div class="section-container">
      <select v-model="selectedUser" @change="fetchSelectedUserResults" class="dropdown">
        <option value="" disabled>Select a user</option>
        <option v-for="(email, userId) in userEmails" :value="userId" :key="userId">{{ email }}</option>
      </select>
      <div class="button-container">
        <button @click="exportToPDF" :disabled="!selectedUser">Export to PDF</button>
        <button @click="exportToXLS" :disabled="!selectedUser">Export to XLS</button>
      </div>
    </div>
    <div v-if="selectedUser && allResults[selectedUser]" class="user-results">
      <div class="section-container">
        <h3>User: {{ userEmails[selectedUser] }}</h3>
        <div v-for="(quizResults, quizId) in allResults[selectedUser]" :key="quizId">
          <h4>Results for {{ titles[quizId] }}</h4>
          <table>
            <thead>
              <tr>
                <th>Question</th>
                <th>Answer</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="question in quizzes[quizId]" :key="question.key">
                <tr v-if="!question.subQuestions">
                  <td>{{ question.question }}</td>
                  <td>{{ formatValue(quizResults[question.key]) || 'No answer' }}</td>
                </tr>
                <tr v-else>
                  <td>{{ question.question }}</td>
                  <td>
                    <table>
                      <tbody>
                        <tr v-for="subQuestion in question.subQuestions" :key="subQuestion.key">
                          <td>{{ subQuestion.question }}</td>
                          <td>{{ formatValue(quizResults[question.key]?.[subQuestion.key]) || 'No answer' }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <div v-if="quizId === 'q2' && Object.keys(quizResults).length > 0">
            <h4>Results for Assets</h4>
            <div v-for="(asset, assetId) in quizResults" :key="assetId">
              <div v-if="assetId !== 'assets'" class="profile-card">
                <h4>{{ asset['assets.assetDetails'] || 'No details' }}</h4>
                <table>
                  <thead>
                    <tr>
                      <th>Question</th>
                      <th>Answer</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Asset type</td>
                      <td>{{ formatValue(asset['assets.assetType']) || 'No answer' }}</td>
                    </tr>
                    <tr>
                      <td>Address of asset</td>
                      <td>{{ formatValue(asset['assets.assetAddress']) || 'No answer' }}</td>
                    </tr>
                    <tr>
                      <td>Does this property have a mortgage, loan, or insurance?</td>
                      <td>{{ formatValue(asset['assets.assetLiabilities']) || 'No answer' }}</td>
                    </tr>
                    <tr>
                      <td>Who owns this property?</td>
                      <td>{{ formatValue(asset['propertyOwnership']) || 'No answer' }}</td>
                    </tr>
                    <tr>
                      <td>Attach property certificates/photos</td>
                      <td>{{ asset['assets.propertyAttachments'] ? 'Files attached' : 'No files attached' }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { db } from '@/firebase/config';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { q1_1, q1_2, q2, q3, q4, q5, q1_1_title, q1_2_title, q2_title, q3_title, q4_title, q5_title } from '@/data/quizQuestions';
import getUser from '@/composables/getUser';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

export default {
  setup() {
    const router = useRouter();
    const { user, isAuthenticated } = getUser();
    const allResults = ref({});
    const selectedUser = ref(null);
    const userEmails = ref({});

    const quizzes = reactive({
      'q1_1': q1_1.value,
      'q1_2': q1_2.value,
      'q2': q2.value,
      'q3': q3.value,
      'q4': q4.value,
      'q5': q5.value
    });

    const titles = reactive({
      'q1_1': q1_1_title.value,
      'q1_2': q1_2_title.value,
      'q2': q2_title.value,
      'q3': q3_title.value,
      'q4': q4_title.value,
      'q5': q5_title.value
    });

    const fetchUserEmails = async (userId) => {
      try {
        const userDetailsDoc = await getDoc(doc(db, "userDetails", userId));
        if (userDetailsDoc.exists()) {
          userEmails.value[userId] = userDetailsDoc.data().email;
        } else {
          userEmails.value[userId] = 'Email not found';
        }
      } catch (error) {
        userEmails.value[userId] = 'Error fetching email';
        console.error(`Error fetching email for user ID ${userId}:`, error);
      }
    };

    const fetchAllUsers = async () => {
      const userDocs = await getDocs(collection(db, "userDetails"));
      const userList = userDocs.docs.map(doc => ({
        id: doc.id,
        email: doc.data().email
      }));
      userEmails.value = userList.reduce((acc, user) => {
        acc[user.id] = user.email;
        return acc;
      }, {});
      return userList.map(user => user.id);
    };

    const fetchQuizResults = async (userId) => {
      const userResults = {};
      const quizIds = ['q1_1', 'q1_2', 'q2', 'q3', 'q4', 'q5'];

      for (const quizId of quizIds) {
        let quizResult = {};
        if (['q3', 'q4', 'q5'].includes(quizId)) {
          const quizDoc = doc(db, `userAnswers/${userId}/${quizId}/data`);
          const quizSnapshot = await getDoc(quizDoc);
          if (quizSnapshot.exists()) {
            quizResult = quizSnapshot.data();
          }
        } else {
          const quizCollection = collection(db, `userAnswers/${userId}/${quizId}`);
          const querySnapshot = await getDocs(quizCollection);
          querySnapshot.docs.forEach(doc => {
            const data = doc.data();
            if (quizId === 'q2') {
              if (!quizResult['assets']) {
                quizResult['assets'] = {};
              }
              quizResult[doc.id] = data;
            } else {
              quizResult[doc.id] = data.answer; // For quizzes without substructures
            }
          });
        }

        userResults[quizId] = quizResult;
      }

      allResults.value[userId] = userResults;
      console.log('Fetched results for user:', userId, allResults.value[userId]);
    };

    const formatValue = (value) => {
      if (Array.isArray(value)) {
        console.log("Formatting array value:", value);
        return value.map(item => {
          if (typeof item === 'object' && item !== null) {
            return Object.entries(item).map(([key, val]) => `${key}: ${val}`).join(', ');
          }
          return item.toString();
        }).join('; ');
      } else if (typeof value === 'object' && value !== null) {
        console.log("Formatting object value:", value);
        return Object.entries(value).map(([key, val]) => `${key}: ${val}`).join(', ');
      } else if (value === null || value === undefined) {
        return 'No answer';
      }
      console.log("Formatting simple value:", value);
      return value.toString();
    };

    const exportToPDF = () => {
      const doc = new jsPDF();
      const user = userEmails.value[selectedUser.value];
      doc.setFontSize(14);
      doc.text(`User: ${user}`, 10, 10);
      const userResults = allResults.value[selectedUser.value];

      let startY = 20;

      for (const quizId in userResults) {
        if (quizId !== 'q2') {
          doc.setFontSize(12);
          doc.text(`Results for ${titles[quizId]}`, 10, startY);
          startY += 10;

          const data = Object.entries(userResults[quizId]).map(([key, value]) => [key, formatValue(value)]);
          doc.autoTable({
            startY,
            head: [['Question', 'Answer']],
            body: data,
            theme: 'grid',
            styles: { fontSize: 10, cellPadding: 2 },
            headStyles: { fillColor: [0, 120, 145] },
          });

          startY = doc.autoTable.previous.finalY + 10;
        } else {
          if (Object.keys(userResults[quizId]).length > 0) {
            doc.setFontSize(12);
            doc.text('Results for Assets', 10, startY);
            startY += 10;

            Object.entries(userResults[quizId]).forEach(([assetId, asset], index) => {
              if (assetId !== 'assets') {
                const assetDetails = Object.entries(asset).map(([key, value]) => [key, formatValue(value)]);
                doc.setFontSize(11);
                doc.text(`Asset ${index + 1}: ${asset['assets.assetDetails'] || 'No details'}`, 10, startY);
                startY += 5;

                doc.autoTable({
                  startY,
                  head: [['Field', 'Value']],
                  body: assetDetails,
                  theme: 'grid',
                  styles: { fontSize: 10, cellPadding: 2 },
                  headStyles: { fillColor: [0, 120, 145] },
                });

                startY = doc.autoTable.previous.finalY + 10;
              }
            });
          }
        }
      }

      doc.save(`${user}_results.pdf`);
    };

    const exportToXLS = () => {
      const user = userEmails.value[selectedUser.value];
      const userResults = allResults.value[selectedUser.value];
      const wb = XLSX.utils.book_new();

      for (const quizId in userResults) {
        const wsData = [];

        if (quizId !== 'q2') {
          wsData.push(['Question', 'Answer']);
          for (const questionKey in userResults[quizId]) {
            wsData.push([questionKey, formatValue(userResults[quizId][questionKey])]);
          }
        } else {
          for (const assetId in userResults[quizId]) {
            if (assetId !== 'assets') {
              const asset = userResults[quizId][assetId];
              wsData.push(['Asset Details', asset['assets.assetDetails'] || 'No details']);
              wsData.push(['Asset type', formatValue(asset['assets.assetType']) || 'No answer']);
              wsData.push(['Address of asset', formatValue(asset['assets.assetAddress']) || 'No answer']);
              wsData.push(['Liabilities', formatValue(asset['assets.assetLiabilities']) || 'No answer']);
              wsData.push(['Ownership', formatValue(asset['propertyOwnership'])?.join(", ") || 'No answer']);
              wsData.push(['Attachments', formatValue(asset['assets.propertyAttachments']) ? 'Files attached' : 'No files attached']);
              wsData.push([]);
            }
          }
        }

        const ws = XLSX.utils.aoa_to_sheet(wsData);
        XLSX.utils.book_append_sheet(wb, ws, titles[quizId]);
      }

      XLSX.writeFile(wb, `${user}_results.xlsx`);
    };

    const handleUserChange = () => {
      if (selectedUser.value) {
        fetchQuizResults(selectedUser.value);
      }
    };

    const fetchSelectedUserResults = async () => {
      console.log(`Fetching results for user: ${selectedUser.value}`);
      await fetchQuizResults(selectedUser.value);
      console.log(`Results for selected user:`, allResults.value[selectedUser.value]);
    };

    onMounted(() => {
      fetchAllUsers();
    });

    return {
      allResults,
      selectedUser,
      quizzes,
      userEmails,
      titles,
      exportToPDF,
      exportToXLS,
      handleUserChange,
      formatValue,
      fetchSelectedUserResults
    };
  }
};
</script>

<style scoped>
.content-container {
  max-width: 600px;
  margin: auto;
  padding-top: 10px;
}

header {
  text-align: center;
  margin-bottom: 10px;
}

header h2 {
  font-size: 18px;
  margin-bottom: 5px;
}

header p {
  font-size: 14px;
  margin-bottom: 10px;
}

.section-container {
  margin-bottom: 20px;
  background-color: #f4f4f8; /* Light grey background for each section */
  padding: 10px;
  border-radius: 8px; /* Rounded corners for section containers */
}

.button-container {
  display: flex;
  justify-content: space-between;
}

select {
  margin-bottom: 20px;
  padding: 8px;
  font-size: 16px;
}

button {
  background-color: #007891;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

button:disabled {
  background-color: #cccccc;
}

button:hover:not(:disabled) {
  background-color: #03a9ca;
}

.user-results {
  margin-top: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  background-color: #f9f9f9; /* Light grey background */
  border-radius: 8px; /* Rounded corners for aesthetic */
  overflow: hidden; /* Ensures the border-radius clips content */
}

th, td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}
</style>
