<template>
  <div class="quiz-container">
    <DarkModeToggle />
    <h2 class="quiz-title">{{ t(title) }}</h2>
    <FormKit type="form" @submit="submitQuiz" class="quiz-form">
      <div v-if="questions.length > 0" class="questions-container">
        <div v-for="(question, index) in displayedQuestions" :key="question.key" class="question-block">
          <p class="question-text">{{ getQuestionNumber(index) }}. {{ t(question.label) }}</p>
          <FormKit
            :type="question.type"
            :name="question.name"
            :placeholder="t(question.placeholder)"
            :help="t(question.help)"
            :options="getTranslatedOptions(question)"
            v-model="answers[question.key]"
            :prefix-icon="question.prefixIcon"
            class="formkit-field"
          />
        </div>
      </div>
      <div class="form-actions">
        <button type="button" @click="goBack" v-if="currentPage > 0" class="nav-button">{{ t('Back') }}</button>
        <button type="button" @click="nextPage" v-if="currentPage < totalPages - 1" class="nav-button">{{ t('Next') }}</button>
      </div>
    </FormKit>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import { db } from '../firebase/config';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import getUser from '@/composables/getUser';
import { useQ1_1Questions } from '@/data/quizQuestions';
import DarkModeToggle from './DarkModeToggle.vue';
import { useI18n } from 'vue-i18n';

export default {
  components: {
    DarkModeToggle,
  },
  setup() {
    const { t } = useI18n();
    
    const router = useRouter();
    const { user } = getUser();
    const userId = ref(user.value?.uid);
    const quizId = 'q1_1';

    const { questions, title } = useQ1_1Questions();
    const answers = ref({});
    const currentPage = ref(0);
    const windowWidth = ref(window.innerWidth);

    const countries = ref([]);
    const isMobile = computed(() => windowWidth.value < 768);

    const questionsPerPage = computed(() => (isMobile.value ? 1 : questions.value.length));
    const totalPages = computed(() => Math.ceil(questions.value.length / questionsPerPage.value));
    const displayedQuestions = computed(() => {
      const start = currentPage.value * questionsPerPage.value;
      const end = start + questionsPerPage.value;
      return questions.value.slice(start, end);
    });

    const getQuestionNumber = (index) => currentPage.value * questionsPerPage.value + index + 1;

    const getTranslatedOptions = (question) => {
      if (question.type === 'select' || question.type === 'radio') {
        const baseKey = `quizzes.q1_1.${question.key}.options`;

        const optionKeys = t(baseKey, { returnObjects: true });

        console.log(`Base Key: ${baseKey}`);
        console.log(`Option Keys:`, optionKeys);

        if (typeof optionKeys === 'object') {
          return Object.keys(optionKeys).map(option => ({
            label: optionKeys[option],
            value: option,
          }));
        }

        return [];
      }
      return undefined;
    };

    function handleResize() {
      windowWidth.value = window.innerWidth;
      console.log('Window resized, width:', windowWidth.value);
    }

    const applyInitialTheme = () => {
      const savedTheme = localStorage.getItem('theme') || 'light';
      if (savedTheme === 'dark') {
        document.body.classList.add('dark-mode');
      } else {
        document.body.classList.remove('dark-mode');
      }
      console.log(`Applied initial theme: ${savedTheme}`);
    };

    onMounted(() => {
      applyInitialTheme();
      window.addEventListener('resize', handleResize);
      fetchCountries();
      fetchAnswers();
      console.log('Component mounted');
    });

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize);
      console.log('Component unmounted');
    });

    const goBack = () => {
      if (currentPage.value > 0) currentPage.value--;
    };

    const nextPage = () => {
      if (currentPage.value < totalPages.value - 1) {
        currentPage.value++;
      } else {
        submitQuiz();
      }
    };

    const submitQuiz = async () => {
      try {
        const batch = db.batch();
        questions.value.forEach((question) => {
          const docRef = doc(db, `userAnswers/${userId.value}/${quizId}/${question.key}`);
          batch.set(docRef, { answer: answers.value[question.key] }, { merge: true });
        });
        await batch.commit();
        console.log('Quiz submitted successfully.');
        router.push('/MyProfile');
      } catch (error) {
        console.error('Failed to submit quiz:', error);
      }
    };

    const handleAnswerChange = (questionKey, value) => {
      answers.value[questionKey] = value;
      const docRef = doc(db, `userAnswers/${userId.value}/${quizId}/${questionKey}`);
      setDoc(docRef, { answer: value }, { merge: true })
        .then(() => console.log(`Updated Firestore with key: ${questionKey} and value: ${value}`))
        .catch((error) => console.error('Failed to update Firestore:', error));
    };

    const fetchAnswers = async () => {
      for (const question of questions.value) {
        const docRef = doc(db, `userAnswers/${userId.value}/${quizId}/${question.key}`);
        try {
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            answers.value[question.key] = docSnap.data().answer;
            console.log(`Loaded answer for ${question.key}: ${docSnap.data().answer}`);
          } else {
            console.log(`No existing answer found for ${question.key}`);
          }
        } catch (error) {
          console.error('Error fetching answer from Firestore:', error);
        }
      }
    };

    const fetchCountries = async () => {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        const data = await response.json();
        countries.value = data.map((country) => country.name.common).sort();

        if (countries.value.includes('Cyprus')) {
          answers.value['country'] = 'Cyprus';
        }
        console.log('Countries fetched:', countries.value);
      } catch (error) {
        console.error('Failed to fetch countries:', error);
      }
    };

    return {
      t,
      title,
      questions,
      answers,
      currentPage,
      totalPages,
      displayedQuestions,
      isMobile,
      countries,
      getQuestionNumber,
      getTranslatedOptions,
      goBack,
      nextPage,
      handleAnswerChange,
      submitQuiz,
    };
  },
};
</script>



<style>
body {
  background-color: #ffffff; /* Default light mode */
  color: #2c3e50;
  transition: background-color 0.3s ease, color 0.3s ease;
}

body.dark-mode {
  background-color: #121212;
  color: #ffffff;
}

.quiz-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Full screen height */
  padding: 20px;
}

.quiz-form {
  width: 100%;
  max-width: 800px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #ffffff;
}

.question-block {
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

/* Set a consistent height for question blocks */
@media (max-width: 768px) {
  .question-block {
    height: 150px; /* Adjust this value as needed */
  }

  .questions-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  }

  .quiz-form {
    display: flex;
    flex-direction: column;
    height: 100%; /* Ensure the form takes up the full height */
  }
}

.form-actions {
  display: flex;
  justify-content: space-between;
  margin-top: auto; /* Push the buttons to the bottom */
}

.nav-button {
  padding: 5px 10px;
  background-color: #009ab3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.nav-button:hover {
  background-color: #007a8c;
}

</style>
