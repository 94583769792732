// src/data/quizQuestions.js
import { ref, computed } from 'vue';

export function useQ1_1Questions() {
  const questions = ref([
    {
      key: "fullName",
      type: "text",
      name: "fullName",
      validation: "required",
      validationBehavior: "live",
      prefixIcon: "text",
    },
    {
      key: "email",
      type: "email",
      name: "email",
      validation: "required|email",
      validationBehavior: "live",
      prefixIcon: "email",
    },
    {
      key: "dob",
      type: "date",
      name: "dob",
      validation: "required",
      validationBehavior: "live",
      prefixIcon: "date",
    },
    {
      key: "idPassport",
      type: "text",
      name: "idPassport",
      validation: "required",
      validationBehavior: "live",
      prefixIcon: "number",
    },
    {
      key: "address",
      type: "text",
      name: "address",
      validation: "required",
      validationBehavior: "live",
      prefixIcon: "text",
    },
    {
      key: "gender",
      type: "select",
      name: "gender",
      validation: "required",
      validationBehavior: "live",
      options: []
    },
    {
      key: "phoneNumber",
      type: "tel",
      name: "phoneNumber",
      validation: "required|tel",
      validationBehavior: "live",
      prefixIcon: "telephone",
    },
    {
      key: "maritalStatus",
      type: "radio",
      name: "maritalStatus",
      validation: "required",
      validationBehavior: "live",
      options: []
    },
    {
      key: "country",
      type: "select",
      name: "country",
      validation: "required",
      validationBehavior: "live",
      options: [] // This will be populated by the countries API call
    }
  ]);

  const localizedQuestions = computed(() =>
    questions.value.map((question) => {
      const baseKey = `quizzes.q1_1.${question.key}`;
      return {
        ...question,
        label: `${baseKey}.label`,
        placeholder: `${baseKey}.placeholder`,
        help: `${baseKey}.help`,
        options: question.options
          ? question.options.map(option => `${baseKey}.options.${option}`)
          : undefined,
      };
    })
  );

  const title = ref('quizTitles.q1_1');

  return {
    questions: localizedQuestions,
    title,
  };
}


/*
export const q1_1 = ref([
 { "question": "What is your full name?", "key": "fullName", "type": "freeTextChars" },
 { "question": "What is your email address?", "key": "email", "type": "email" },
 { "question": "What is your date of birth?", "key": "dob", "type": "date" },
 { "question": "What is your ID or Passport number?", "key": "idPassport", "type": "freeTextChars" },
 { "question": "What is your address?", "key": "address", "type": "freeTextChars" },
 { "question": "What is your gender?", "options": ["Male", "Female", "Other"], "key": "gender", "type": "multiple" },
 { "question": "What is your phone number?", "key": "phoneNumber", "type": "phoneNumber" },
 { "question": "What is your marital status?", "key": "maritalStatus", "type": "multiple", "options": ["Single", "Married", "Divorced", "Widowed"] },
 { "question": "What is your current location", "key": "locationAndDate", "type": "freeTextChars" }
]);
*/
export const q1_2 = ref([
    { 
      "question": "Do you have a spouse?", 
      "key": "hasSpouse", 
      "type": "yn" 
    },
    { 
      "question": "Please provide the name and email of your spouse.", 
      "key": "spouseDetails", 
      "type": "multipleAnswers", 
      "condition": { "key": "hasSpouse", "value": "Yes" },
      "fields": [
        { "name": "name", "type": "text", "label": "Full Name" },
        { "name": "email", "type": "email", "label": "Email" }
      ] 
    },
    { 
      "question": "Do you have children?", 
      "key": "hasChildren", 
      "type": "yn" 
    },
    { 
      "question": "Please provide the names and ages of your children.", 
      "key": "childrenDetails", 
      "type": "dynamicList", 
      "condition": { "key": "hasChildren", "value": "Yes" },
      "fields": [
        { "name": "name", "type": "text", "label": "Full Name" },
        { "name": "age", "type": "number", "label": "Age" }
      ] 
    },
    { 
      "question": "In order of preference, please list the full names of individuals who you would like to appoint as guardians for your child, should there be no surviving parent.", 
      "key": "childGuardian", 
      "type": "dynamicList", 
      "condition": { "key": "hasChildren", "value": "Yes" },
      "fields": [
        { "name": "name", "type": "text", "label": "Full Name" }
      ] 
    },
    { 
      "question": "Do you have pets?", 
      "key": "hasPets", 
      "type": "yn" 
    },
    { 
      "question": "Please provide details of you pets (Type of pet, name, age, breed, and any other relevant information)", 
      "key": "petDetails", 
      "type": "dynamicList", 
      "condition": { "key": "hasPets", "value": "Yes" },
      "fields": [
        { "name": "type", "type": "dropdown", "label": "Type of Pet", "options": ["Dog", "Cat", "Bird", "Other"] },
        { "name": "name", "type": "text", "label": "Name" },
        { "name": "age", "type": "number", "label": "Age" },
        { "name": "breed", "type": "text", "label": "Breed" }
      ] 
    },
    { 
      "question": "Describe the care requirements for your pets and name the person(s) who should take responsibility for them. Please include names and contact information.", 
      "key": "petCaretakers", 
      "type": "dynamicList", 
      "condition": { "key": "hasPets", "value": "Yes" },
      "fields": [
        { "name": "requirements", "type": "text", "label": "Care Requirements" },
        { "name": "caretakerName", "type": "text", "label": "Caretaker's Name" },
        { "name": "contactInfo", "type": "text", "label": "Contact Information" }
      ] 
    }
  ]);

export const q1_2_title = ref("1ST SECTION - Guardianship and Pet Care");

export const q2 = ref([
 {
 "question": "Add an asset", "key": "assets", "type": "dynamicAssetList",
 "subQuestions": [
 { "question": "Asset description (e.g., pensions, investments, life insurance policies, cars, bikes, jewellery, stocks, shares, crypto, cash, coins)", "key": "assetDetails", "type": "freeTextChars" },
 { "question": "Asset type", "key": "assetType", "type": "dropdown", "options": ["Pensions", "Investments", "Life Insurance Policies", "Cars", "Bikes", "Jewellery", "Stocks", "Shares", "Crypto", "Cash", "Coins", "Bank Accounts", "Property", "Other"] },
 { "question": "Address of asset", "key": "assetAddress", "type": "freeTextChars" },
 { "question": "Does this property have a mortgage, loan, or insurance?", "key": "assetLiabilities", "type": "yn" },
 { "question": "Who owns this property?", "key": "propertyOwnership", "type": "multiple", "options": ["I am the only owner", "I own jointly with someone else"] },
 { "question": "Attach property certificates/photos", "key": "propertyAttachments", "type": "fileUpload" }
 ]
 }
]);

export const q2_title = ref("2ND SECTION - ASSETS AND LIABILITIES");

export const q3 = ref([
 { "question": "Full Name of First Choice", "key": "firstExecutorName", "type": "freeTextChars" },
 { "question": "Relationship", "key": "firstExecutorRelationship", "type": "freeTextChars" },
 { "question": "Select who you would like your executors to be", "key": "executorChoice", "type": "multiple", "options": ["Friends and Family", "ewill Trustees", "Friends and Family and eWill Trustees"] },
 { "question": "Would you like to nominate someone else to act as your digital executor instead?", "key": "nominateDigitalExecutor", "type": "yn" },
 { "question": "Would you like your digital executor to have access to the contents of your electronic communication?", "key": "digitalExecutorAccess", "type": "yn", "condition": { "key": "nominateDigitalExecutor", "value": "YES" } },
 { "question": "Full Name of First Choice of Digital Executor", "key": "digitalExecutorName", "type": "freeTextChars", "condition": { "key": "nominateDigitalExecutor", "value": "YES" } },
 { "question": "Relationship", "key": "digitalExecutorRelationship", "type": "freeTextChars", "condition": { "key": "nominateDigitalExecutor", "value": "YES" } }
]);

export const q3_title = ref("3RD SECTION - EXECUTORS");

export const q3_heading = ref({
  title: "What actually is an Executor?",
  description: "The executor of your will is the person in charge of managing all the admin that needs to happen after you die, things like:",
  listItems: [
    "Applying for probate",
    "Paying any taxes, debts and funeral costs",
    "Distributing assets or property",
    "Carrying out last wishes"
  ],
  conclusion: "You can choose a trusted friend, family member or a professional."
});

export const q3_midheading = ref({
  paragraph1: "We recommend choosing more than one, especially if any beneficiaries of your will are currently under 18.",
  paragraph2: "Thinking about choosing a professional executor? About two thirds of people choose a professional executor to help with their estate and taxes after they die."
});

export const q4 = ref([
    {
      question: "In order of preference, who will be responsible for carrying out your funeral?",
      key: "funeralResponsibles",
      type: "dynamicList",
      fields: [
        { name: "fullName", type: "text", label: "Full Name" },
        { name: "relationship", type: "text", label: "Relationship" }
      ]
    },
    {
      question: "What are your final resting place preferences and further instructions?",
      key: "finalRestingPlace",
      type: "freeTextChars"
    },
    {
      question: "Do you want to leave a message, or explain your decision, to any of your beneficiaries?",
      key: "leaveMessage",
      type: "yn"
    },
    {
      question: "A simple message will be treasured by your loved ones. Please write your messages below:",
      key: "messageContent",
      type: "freeTextChars",
      condition: { key: "leaveMessage", value: "YES" }
    }
  ]);

export const q4_title = ref("YOUR FUNERAL WISHES (OPTIONAL)");

export const q5 = ref([
 { "question": "WOULD YOU LIKE TO ATTACH A PERSONAL STATEMENT TO YOUR WILL?", "key": "attachPersonalStatement", "type": "yn" },
 { "question": "What secret don't you want to take to your grave?", "key": "secret", "type": "freeTextChars", "condition": { "key": "attachPersonalStatement", "value": "YES" } },
 { "question": "What should everyone wear at your funeral?", "key": "funeralWear", "type": "freeTextChars", "condition": { "key": "attachPersonalStatement", "value": "YES" } },
 { "question": "What memorial birthday celebration would you prefer every year on your birthday?", "key": "birthdayCelebration", "type": "freeTextChars", "condition": { "key": "attachPersonalStatement", "value": "YES" } },
 { "question": "What songs are on the playlist of your life?", "key": "playlist", "type": "freeTextChars", "condition": { "key": "attachPersonalStatement", "value": "YES" } },
 { "question": "In a sentence, how do you want to be remembered?", "key": "remembered", "type": "freeTextChars", "condition": { "key": "attachPersonalStatement", "value": "YES" } }
]);

export const q5_title = ref("5TH SECTION - PERSONAL STATEMENT (OPTIONAL)");
