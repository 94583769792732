//main.js

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { auth } from './firebase/config';
import vuetify from './plugins/vuetify';

import { plugin, defaultConfig } from '@formkit/vue';
import { createProPlugin, inputs } from '@formkit/pro';
import { rootClasses } from '../formkit.theme.mjs';
import i18n from './i18n.js'; 
import CountryFlag from 'vue-country-flag-next'

const pro = createProPlugin('fk-769a0d2ecb0', inputs)

let appInstance;

auth.onAuthStateChanged(user => {
  if (!appInstance) {
    appInstance = createApp(App)
      .use(store)
      .use(router)
      .use(vuetify)
      .use(i18n)
      .component('CountryFlag', CountryFlag) // Register the CountryFlag component globally
      .use(plugin, defaultConfig(
        { 
          plugins: [pro], 
          config: { rootClasses } 
        }))
      .mount('#app');
  }

  if (user) {
    console.log("Logged in as:", user.uid);

    // Redirect authenticated users to MyProfile if they are on login or signup pages
    if (router.currentRoute.value.path === '/login' || router.currentRoute.value.path === '/signup') {
      router.push('/MyProfile');
    }
  } else {
    console.log("User is signed out");

    // Redirect non-authenticated users trying to access protected routes
    if (router.currentRoute.value.meta.requiresAuth) {
      router.push('/login');
    }
  }
});
