<template>
    <footer class="site-footer">
      <div class="container">
        <br>   <br>  

        <div class="subscription-section">
            
          <form class="subscription-form">

            <div class="form-content">
              <div class="form-text">
                <p><strong>{{ $t('footer.subscribeMessage') }}</strong></p>
              </div>
              <div class="form-input">
                <input
                  type="email"
                  id="exampleFormControlInputEmail2"
                  :placeholder="$t('footer.emailPlaceholder')"
                />
                <label
                  for="exampleFormControlInputEmail2"
                  class="form-label"
                >{{ $t('footer.emailPlaceholder') }}</label>
              </div>
              <div class="form-button">
                <button type="button" class="subscribe-button">
                  {{ $t('footer.subscribeButton') }}
                </button>
              </div>
            </div>
          </form>
        </div>
  
        <div class="footer-content">
          <div class="contact-info">
            <p><i class="material-icons">phone</i> +123 456 7890</p>
            <p><i class="material-icons">email</i> info@actserv.com</p>
          </div>
          <div class="footer-links">
            <router-link to="/cookies">{{ $t('footer.cookies') }}</router-link>
            <router-link to="/privacy">{{ $t('footer.privacy') }}</router-link>
            <router-link to="/terms">{{ $t('footer.terms') }}</router-link>
          </div>
          <div class="footer-social">
            <a href="https://facebook.com" target="_blank" aria-label="Facebook">
              <i class="fab fa-facebook-f"></i>
            </a>
            <a href="https://instagram.com" target="_blank" aria-label="Instagram">
              <i class="fab fa-instagram"></i>
            </a>
          </div>
        </div>
      </div>
  
      <div class="footer-bottom">
        <p>&copy; 2024 ACTServ Technology Ltd. {{ $t('footer.allRightsReserved') }}</p>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: "FooterView",
  };
  </script>
  
  <style lang="scss" scoped>
  .site-footer {
    background-color: #015967;
    color: #fff;
    text-align: center;
  
    .container {
      max-width: 1140px;
      margin: 0 auto;
      padding: 0 15px;
    }
  
    .subscription-section {
      background-color: #9db3c0;
      padding: 20px;
      margin-bottom: 20px;
      border-radius: 8px;
  
      .subscription-form {
        display: flex;
        align-items: center;
        justify-content: center;
  
        .form-content {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 20px;
  
          .form-text {
            margin-right: 20px;
          }
  
          .form-input {
            position: relative;
  
            input {
              width: 300px;
              padding: 10px;
              border: 1px solid #ccc;
              border-radius: 4px;
              background-color: #fff;
              color: #333;
              font-size: 16px;
            }
  
            .form-label {
              position: absolute;
              left: 10px;
              top: 50%;
              transform: translateY(-50%);
              color: #666;
              transition: all 0.2s;
            }
  
            input:focus + .form-label,
            input:not(:placeholder-shown) + .form-label {
              top: 0;
              font-size: 12px;
              color: #009ab3;
            }
          }
  
          .form-button {
            text-align: center;
  
            .subscribe-button {
              padding: 10px 20px;
              font-size: 14px;
              background-color: #009ab3;
              color: white;
              border: none;
              cursor: pointer;
              border-radius: 4px;
              transition: background-color 0.3s;
  
              &:hover {
                background-color: #007a8c;
              }
            }
          }
        }
      }
    }
  
    .footer-content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 20px;
  
      .contact-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
  
        p {
          display: flex;
          align-items: center;
          gap: 10px;
          margin: 5px 0;
          font-size: 14px;
          color: #fff;
  
          i.material-icons {
            font-size: 18px;
            color: #009ab3;
          }
        }
      }
  
      .footer-links {
        display: flex;
        flex-direction: column;
  
        a {
          color: #fff;
          text-decoration: none;
          margin-bottom: 10px;
          transition: color 0.3s;
          font-size: 14px;
  
          &:hover {
            color: #009ab3;
          }
        }
      }
  
      .footer-social {
        display: flex;
        justify-content: center;
        gap: 15px;
  
        a {
          color: #fff;
          font-size: 24px;
          transition: color 0.3s;
  
          &:hover {
            color: #009ab3;
          }
        }
      }
    }
  
    .footer-bottom {
      background-color: #016777;
      padding: 10px 0;
      text-align: center;
      margin: 0;
      p {
        margin: 0;
        font-size: 12px;
      }
    }
  }

  </style>
  