<template>
  <div class="panel panel-default">
    <div class="panel-heading">{{ quizTitle }}</div>
    <div class="panel-body">
      <div class="question-container">
        <div v-if="displayedQuestions && displayedQuestions.length > 0" class="question-frame">
          <div class="description">
            <h3>{{ q3_heading.title }}</h3>
            <p>{{ q3_heading.description }}</p>
            <ul class="indented-list">
              <li v-for="item in q3_heading.listItems" :key="item">{{ item }}</li>
            </ul>
            <p>{{ q3_heading.conclusion }}</p>
          </div>
          <div v-for="(question, index) in displayedQuestions" :key="question.key" class="question-block">
            <p class="question-text">{{ getQuestionNumber(index) }}. {{ question.question }}</p>

            <!-- Display mid-heading after the second question -->
            <div v-if="index === 2" class="mid-heading">
              <p>{{ q3_midheading.paragraph1 }}</p>
              <p>{{ q3_midheading.paragraph2 }}</p>
            </div>
          
            <!-- Dynamic List questions -->
            <div v-if="question.type === 'dynamicList'" class="dynamic-list-container">
              <div v-for="(entry, entryIndex) in answers[question.key]" :key="entryIndex" class="entry">
                <div v-if="question.key === 'childrenDetails'" class="input-group">
                  <div class="input-container">
                    <input type="text" v-model="entry.name" @input="handleDynamicListChange(question.key, entryIndex, 'name', entry.name)" required>
                    <label class="floating-label">Full Name</label>
                  </div>
                  <div class="input-container">
                    <input type="number" v-model="entry.age" @input="handleDynamicListChange(question.key, entryIndex, 'age', entry.age)" required>
                    <label class="floating-label">Age</label>
                  </div>
                  <button @click="removeEntry(question.key, entryIndex)" class="remove-button">−</button>
                </div>
              </div>
              <button @click="addEntry(question.key)" class="add-button">+</button>
            </div>

            <!-- Other input types -->
            <div v-else-if="question.type === 'email'" class="input-container">
              <input type="email" v-model="answers[question.key]" @input="handleAnswerChange(question.key, $event.target.value)" required>
              <label class="floating-label">Email</label>
              <p v-if="!isEmailValid(answers[question.key]) && answers[question.key]" class="error-message">
                Please enter a valid email address.
              </p>
            </div>

            <!-- Phone number input with validation -->
            <div v-if="question.type === 'phoneNumber'" class="input-container">
              <input type="tel" v-model="answers[question.key]" @input="handleAnswerChange(question.key, $event.target.value)" required>
              <label class="floating-label">Phone Number</label>
              <p v-if="!isPhoneNumberValid(answers[question.key]) && answers[question.key]" class="error-message">
                Please enter a valid phone number (e.g., +357 99 xx xx xx).
              </p>
            </div>

            <!-- Yes/No questions -->
            <div v-if="question.type === 'yn'" class="button-container yn">
              <button v-for="option in ['Yes', 'No']" :key="option" @click="handleAnswerChange(question.key, option)" :class="{'selected-option': answers[question.key] === option, 'answered-option': answers[question.key], 'non-answered-option': !answers[question.key]}" class="answer-button yn-button">
                {{ option }}
              </button>
            </div>

            <!-- Free text input (characters) -->
            <div v-else-if="question.type === 'freeTextChars'" class="input-container">
              <input type="text" v-model="answers[question.key]" @input="handleAnswerChange(question.key, $event.target.value)" required>
              <label class="floating-label">Type your answer here...</label>
            </div>

            <!-- Free text input (numbers) -->
            <div v-else-if="question.type === 'freeTextNumbers'" class="input-container">
              <input type="number" v-model="answers[question.key]" @input="handleAnswerChange(question.key, $event.target.value)" required>
              <label class="floating-label">Type your answer here...</label>
            </div>

            <!-- Date input -->
            <div v-else-if="question.type === 'date'" class="input-container"> 
              <input type="date" v-model="answers[question.key]" @input="handleAnswerChange(question.key, $event.target.value)" required>
              <label class="floating-label">Select a date</label>
            </div>

            <!-- Multiple choice questions -->
            <div v-else-if="question.type === 'multiple'" class="button-container multiple">
              <button v-for="option in question.options" :key="option" @click="handleAnswerChange(question.key, option)" :class="{'selected-option': answers[question.key] === option, 'answered-option': answers[question.key], 'non-answered-option': !answers[question.key]}" class="answer-button multiple-choice-button">
                {{ option }}
              </button>
            </div>
          </div>

          <!-- Navigation buttons -->
          <div class="navigation-buttons">
            <button @click="saveAndContinue" class="next-button">Save and Continue</button>
          </div>
        </div>
        <div v-else>
          <h2>Quiz Complete</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { db } from '../firebase/config';
import { collection, setDoc, getDocs, query, doc, getDoc } from 'firebase/firestore';
import getUser from '@/composables/getUser';
import { q3, q3_title,q3_heading,q3_midheading  } from '@/data/quizQuestions';
import '/src/assets/css/styles.css';

export default {
  setup() {
    const router = useRouter();
    const { user } = getUser();
    const userId = user.value.uid;
    const quizId = 'q3';

    const questions = q3;
    const quizTitle = q3_title.value;

    const answers = ref({
      firstExecutorName: '',
      firstExecutorRelationship: '',
      executorChoice: [],
      nominateDigitalExecutor: '',
      digitalExecutorAccess: '',
      digitalExecutorName: '',
      digitalExecutorRelationship: '',
      termsAccepted: false,
    });

    const handleAnswerChange = async (key, value) => {
      answers.value[key] = value;
      const docRef = doc(db, `userAnswers/${userId}/${quizId}/data`);
      try {
        await setDoc(docRef, { [key]: value }, { merge: true });
        console.log(`Updated Firestore with key: ${key} and value: ${value}`);
      } catch (error) {
        console.error("Failed to update Firestore:", error);
      }
    };

    const handleMultipleSelection = async (key, option) => {
      if (!answers.value[key]) {
        answers.value[key] = [];
      }

      const currentIndex = answers.value[key].indexOf(option);
      if (currentIndex === -1) {
        answers.value[key].push(option);
      } else {
        answers.value[key].splice(currentIndex, 1);
      }

      await handleAnswerChange(key, answers.value[key]);
    };

    const saveAndContinue = () => {
      console.log('Answers:', answers.value);
      router.push('/MyProfile'); // Adjust as necessary to match your app's routing
    };

    onMounted(() => {
      fetchAnswers();
    });

    const fetchAnswers = async () => {
      try {
        const docRef = doc(db, `userAnswers/${userId}/${quizId}/data`);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          Object.assign(answers.value, docSnap.data());
        }
      } catch (error) {
        console.error("Error fetching answers from Firestore:", error);
      }
    };

    const displayedQuestions = computed(() => {
      return questions.value.filter(question => {
        if (!question.condition) return true;
        const { key, value } = question.condition;
        return answers.value[key] === value;
      });
    });

    const getQuestionNumber = (index) => index + 1;

    return {
      answers,
      questions,
      quizTitle,
      handleAnswerChange,
      handleMultipleSelection,
      saveAndContinue,
      displayedQuestions,
      getQuestionNumber,
      q3_heading,
      q3_midheading
    };
  }
};
</script>
<style scoped>
.input-container {
  position: relative;
  margin-top: 20px;
  border: 1px solid #ccc; /* Add border */
  border-radius: 4px; /* Rounded corners */
  background-color: #fff; /* White background */
  box-sizing: border-box;
  padding: 10px; /* Add some padding */
  display: flex;
  width: 100%;
  font-size: 14px;
}

.floating-label {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 14px;
  color: #aaa;
  pointer-events: none;
  transition: 0.2s ease all;
}

.input-container input:focus + .floating-label,
.input-container input:not(:placeholder-shown) + .floating-label {
  top: -20px;
  left: 10px;
  font-size: 12px;
  color: #009AB3;
}

.button-container.yn,
.button-container.multiple {
  display: flex;
  justify-content: space-around;
  gap: 10px;
}

.button-container.multiple {
  flex-wrap: wrap;
  justify-content: flex-start;
}


.terms-section {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.navigation-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.description {
  margin-bottom: 10px;
}

.indented-list {
  padding-left: 40px; /* Adjust this value to indent the list items more */
}

.indented-list li {
  margin-bottom: 5px; /* Optional: Add some space between list items */
}

</style>
