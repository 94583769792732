//src\plugins\vuetify.js
import { createVuetify } from 'vuetify';
import 'vuetify/styles';
import { VApp, VAppBar, VMain, VContainer, VSelect, VCard, VCardTitle, VCardText, VSpacer, VBtn, VDialog, VCardActions } from 'vuetify/components';

export default createVuetify({
  components: {
    VApp,
    VAppBar,
    VMain,
    VContainer,
    VSelect,
    VCard,
    VCardTitle,
    VCardText,
    VSpacer,
    VBtn,
    VDialog,
    VCardActions,
  },
});
