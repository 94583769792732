import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getFirestore, Timestamp } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';


const firebaseConfig = {
    apiKey: "AIzaSyAS9G62I8LDd8sYlkVEuukUL5UbjA-mcSs",
    authDomain: "ewill-d6f34.firebaseapp.com",
    projectId: "ewill-d6f34",
    storageBucket: "ewill-d6f34.appspot.com",
    messagingSenderId: "187277803242",
    appId: "1:187277803242:web:2cc9f075a0349db9f36193",
    measurementId: "G-DJH85VHRLK"
  };
  
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Analytics
const analytics = getAnalytics(app);

// Initialize Firestore and export it
const db = getFirestore(app);

// Initialize Firebase Authentication and export it
const auth = getAuth(app);

// Export Timestamp from Firestore for use in your application
export { db, auth, Timestamp };

// If you need to export the analytics instance:
export { analytics };

// Export the timestamp function to use for creating timestamps
export const timestamp = Timestamp.now;
