import { ref } from 'vue';
import { auth as projectAuth } from '../firebase/config';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { nextTick } from 'vue';

const error = ref(null);

const login = async (email, password, router) => {
  error.value = null;
  try {
      const res = await signInWithEmailAndPassword(projectAuth, email, password);
      console.log('Sign in successful:', res.user.email);
      await nextTick();  // Wait for Vue to finish updating the DOM
      await router.push('/MyProfile').catch(err => {
        console.error('Router push error:', err);
      });
    } 
    catch (err) 
    {
    console.error('Login error:', err.message);
    error.value = 'Login failed. Please try again.';
  }
};

const useLogin = () => {
  return { error, login };
};

export default useLogin;
