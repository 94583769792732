<template>
    <div>
      <h1>{{ $t('footer.cookies') }}</h1>
      <p>Content for accepting cookies...</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Cookies',
  };
  </script>
  
  <style scoped>
    body {
      padding-top: 60px; /* Offset content by the height of the navigation menu */
    }
  </style>
  