<template>
  <div class="mode-toggle-container">
    <div class="mode-toggle" @click="toggleDarkMode">
      <div class="toggle">
        <div class="toggle-thumb" :style="toggleStyle"></div>
      </div>
      <span class="toggle-icon">
        <i :class="darkMode ? 'fas fa-sun' : 'fas fa-moon'"></i>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      darkMode: false,
    };
  },
  computed: {
    toggleStyle() {
      return {
        transform: this.darkMode ? 'translateX(24px)' : 'translateX(0)',
      };
    },
  },
  methods: {
    toggleDarkMode() {
      this.darkMode = !this.darkMode;
      if (this.darkMode) {
        document.body.classList.add('dark-mode');
      } else {
        document.body.classList.remove('dark-mode');
      }
      localStorage.setItem('theme', this.darkMode ? 'dark' : 'light');
    },
    applyInitialTheme() {
      const savedTheme = localStorage.getItem('theme');
      this.darkMode = savedTheme === 'dark';
      if (this.darkMode) {
        document.body.classList.add('dark-mode');
      }
    },
  },
  mounted() {
    this.applyInitialTheme();
  },
};
</script>

<style scoped>
.mode-toggle-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
}

.mode-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 10px;
  background-color: var(--mode-toggle-bg, #015967);
  border-radius: 24px;
  transition: background-color 0.5s ease;
}

.toggle {
  position: relative;
  width: 48px;
  height: 24px;
  background-color: #ffffff;
  border-radius: 24px;
  transition: background-color 0.5s ease;
}

.toggle-thumb {
  position: absolute;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #a5abba;
  top: 1px;
  left: 1px;
  transition: transform 0.3s ease;
}

.toggle-icon {
  margin-left: 10px;
  color: #ffffff;
  font-size: 18px;
}

</style>
