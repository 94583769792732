<template>
  <div class="home">
    <div class="intro-container">
      <h1 class="fade-in title">{{ $t('NewUser.welcomeTitle') }}</h1>
      <p class="fade-in subtitle">{{ $t('NewUser.subtitle') }}</p>
      <p class="fade-in description">{{ $t('NewUser.description1') }}</p>
      <p class="fade-in description">{{ $t('NewUser.description2') }}</p>
      <router-link to="/quiz" class="start-button fade-in">{{ $t('NewUser.startButton') }}</router-link>
    </div>
  </div>
</template>

<script>
import '/src/assets/css/styles.css';

export default {
  name: 'Home',
}
</script>

<style scoped>
.home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
}

.intro-container {
  text-align: center;
  background: rgba(255, 255, 255, 0.85);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
  animation: slideUp 1s ease-in-out forwards;
}

.title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
}

.subtitle {
  font-size: 1.5rem;
  color: #555;
  margin-bottom: 10px;
}

.description {
  font-size: 1rem;
  color: #666;
  margin-bottom: 15px;
}

.start-button {
  display: inline-block;
  padding: 12px 24px;
  color: #fff;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  border-radius: 50px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  text-decoration: none;
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.3);
}

.start-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 16px rgba(0, 123, 255, 0.4);
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
