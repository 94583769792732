// src/i18n.js
import { createI18n } from 'vue-i18n';

const messages = {
  en: {
    menu: {
      home: 'Home',
      about: 'About',
      services: 'Services',
      contact: 'Contact',
    },
    sections: {
      whyUs: 'Why Us?',
      whyUsContent: `Because through the digital applications we offer and the control of the process by our lawyers, 
                     we give you the opportunity to legally, quickly, and safely create your will, list your wishes, 
                     and secure your information.`,
      prepareForFuture: 'Prepare for the Future with Ease and Security',
      prepareForFutureContent: `We understand how important it is to have a clear and official plan for your future and that 
                                of your loved ones.`,
      steps: 'In Three Simple Steps',
      step1: 'Start by answering a series of questions.',
      step2: 'Once you’ve completed your answers, your will will be reviewed and approved by our professionals.',
      step3: 'The final step is to print your will and sign it.',
      startNow: 'Start Now!'
    },
    contactUs: {
      title: 'Contact Us',
      firstName: 'First Name*',
      lastName: 'Last Name*',
      phone: 'Phone',
      generalInfo: 'General Information',
      newsletter: 'Yes, sign me up for your newsletter.',
      submit: 'Send message',
    },
    footer: {
      subscribeMessage: 'Sign up for our newsletter and receive the Farewell Planner for free!',
      emailPlaceholder: 'Email address',
      subscribeButton: 'Subscribe',
      allRightsReserved: 'All rights reserved.',
      cookies: 'Accepting Cookies',
      privacy: 'Privacy Policy',
      terms: 'Terms and Conditions',
      cookiesContent: 'This is the cookies policy content in English.',
      privacyContent: 'This is the privacy policy content in English.',
      termsContent: 'This is the terms and conditions content in English.'
    },
    NewUser: {
      welcomeTitle: "Welcome to eWill",
      subtitle: "We are here to guide you through the process.",
      description1: "To begin, we will ask you 9 simple questions to better understand your needs.",
      description2: "Based on your responses, we'll determine the best way to proceed with your will—whether online, by phone, or in-person.",
      startButton: "Start Now",
    },
    NewQuiz: {
      quizComplete: 'Quiz Complete',
      thankYouMessage: 'Thank you for completing our quiz.',
      consultantMessageBasedOnAnswers: 'Based on your responses, we recommend speaking with a consultant for the following reasons:',
      consultantMessage1: 'One of our consultants will contact you to discuss your unique circumstances.',
      consultantMessage2: 'This is a free no-obligation call, that takes no longer than 5 minutes.',
      consultantMessage3: 'Please submit your phone number and preferred time for calling you.',
      nameLabel: 'Name*:',
      emailLabel: 'Email*:',
      phoneLabel: 'Phone*:',
      commentLabel: 'Comment:',
      senderEmailLabel: 'Sender Email*:',
      submitButton: 'Submit',
      thankYouTitle: 'Thank You',
      startWillNow: 'Start your will now!',
      enterNamePlaceholder: 'Enter your name',
      enterEmailPlaceholder: 'Enter your email',
      enterPhonePlaceholder: 'Enter your phone number',
      enterCommentPlaceholder: 'Enter additional comments',
      enterSenderEmailPlaceholder: 'Enter sender email',
      selectCountryLabel: 'Select your country',
      closeButton: 'Close'
    },
    NewQuizquestions: {
      q1: "Do you have a partner?",
      q2: "Do you have children?",
      q3: "Do you have children from a previous marriage?",
      q4: "Do you own any property?",
      q5: "Are you a business owner or director/shareholder of a company?",
      q6: "Do you have a mortgage/debt or liability?",
      q7: "Do you live in Cyprus?",
      q8: "Is everything you own in Cyprus?"
    },
    NewQuizfeedbackReasons: {
      businessOwnerOrComplicated: '> You indicated you are a business owner or have a complicated situation',
      notInCyprus: '> You indicated that you do not reside in Cyprus',
      nonCyprusResidence: '> Your permanent residence is not in Cyprus',
    },
    quizTitles: {
      q1_1: "1ST SECTION - Personal Information",
      q2_1: "2ND SECTION - Other Information", // Example for another quiz
    },
    quizzes: {
      q1_1: {
        fullName: {
          question: "What is your full name?",
          label: "Full Name",
          placeholder: "Enter your full name",
          help: "Please provide your complete legal name.",
        },
        email: {
          question: "What is your email address?",
          label: "Email Address",
          placeholder: "Enter your email address",
          help: "We'll never share your email with anyone else.",
        },
        dob: {
          question: "What is your date of birth?",
          label: "Date of Birth",
          placeholder: "Select your date of birth",
          help: "Provide your date of birth.",
        },
        idPassport: {
          question: "What is your ID or Passport number?",
          label: "ID/Passport Number",
          placeholder: "Enter your ID or Passport number",
          help: "Please enter your ID or Passport number.",
        },
        address: {
          question: "What is your address?",
          label: "Address",
          placeholder: "Enter your address",
          help: "Please provide your current residential address.",
        },
        gender: {
          question: "What is your gender?",
          label: "Gender",
          placeholder: "Select your gender",  
          options: ["Male", "Female", "Other"],
          help: "Select your gender.",
        },
        phoneNumber: {
          question: "What is your phone number?",
          label: "Phone Number",
          placeholder: "Enter your phone number",
          help: "Please enter a valid phone number.",
        },
        maritalStatus: {
          question: "What is your marital status?",
          label: "Marital Status",
          placeholder: "Select your marital status", 
          options: ["Single", "Married", "Divorced", "Widowed"],
          help: "Select your current marital status.",
        },
        country: {
          question: "What is your current location?",
          label: "Current Location",
          placeholder: "Select your country",
          help: "Please select your current country.",
        },
      },
      q2_1: {
        // Add questions for the second quiz here
        question1: {
          question: "Question 1 for Quiz 2",
          label: "Question 1",
          placeholder: "Enter your answer",
          help: "Help text for question 1 of quiz 2.",
        },
      },
    },
  },
  gr: {
    simpleTestKey: "ενα τεστ.", // Added simple test key
    menu: {
      home: 'Αρχικη',
      about: 'Σχετικα',
      services: 'Υπηρεσιες',
      contact: 'Επικοινωνια',
    },
    sections: {
      whyUs: 'Γιατί Εμάς;',
      whyUsContent: `Γιατί μέσα από τις ψηφακές εφαρμογές που διαθέτουμε και με τον έλεγχο της διαδικασίας από τους δικηγόρους μας, 
                     σας προσφέρουμε την ευκαιρεία να δημιουργήσετε νόμιμα, γρήγορα και με ασφάλεια τη διαθήκη σας.`,
      prepareForFuture: 'Προετοιμαστείτε για το Μέλλον με Ευκολία και Ασφάλεια',
      prepareForFutureContent: `Κατανοούμε πόσο σημαντικό είναι να έχετε ένα σαφές και επίσημο σχέδιο για το μέλλον σας 
                                και των αγαπημένων σας.`,
      steps: 'Με τρία απλά Βήματα',
      step1: 'Ξεκινήστε απαντώντας σε μια σειρά από ερωτήσεις.',
      step2: 'Μετά την ολοκλήρωση των απαντήσεων σας, η διαθήκη σας θα εξεταστεί και θα εγκριθεί από τους επαγγελματίες μας.',
      step3: 'Το τελικό βήμα είναι να εκτυπώσετε τη διαθήκη και να την υπογράψετε.',
      startNow: 'Ξεκίνα τώρα!'
    },
    contactUs: {
      title: 'Επικοινωνήστε Μαζί μας',
      firstName: 'Όνομα*',
      lastName: 'Επίθετο*',
      phone: 'Τηλέφωνο',
      generalInfo: 'Γενικές Πληροφορίες',
      newsletter: 'Ναι, εγγράψτε με στο ενημερωτικό σας δελτίο.',
      submit: 'Αποστολή μηνύματος',
    },
    footer: {
      subscribeMessage: 'Εγγραφείτε και λάβετε δωρεάν το Farewell Planner!',
      emailPlaceholder: 'Διεύθυνση email',
      subscribeButton: 'Εγγραφή',
      allRightsReserved: 'Όλα τα δικαιώματα διατηρούνται.',
      cookies: 'Αποδοχή Cookies',
      privacy: 'Πολιτική Απορρήτου',
      terms: 'Όροι και Προϋποθέσεις',
      cookiesContent: 'Αυτό είναι το περιεχόμενο της πολιτικής cookies στα Ελληνικά.',
      privacyContent: 'Αυτό είναι το περιεχόμενο της πολιτικής απορρήτου στα Ελληνικά.',
      termsContent: 'Αυτό είναι το περιεχόμενο των όρων και προϋποθέσεων στα Ελληνικά.'
    },
    quizTitles: {
      q1_1: "1Η ΕΝΟΤΗΤΑ - Προσωπικές Πληροφορίες",
      q1_2: "2Η ΕΝΟΤΗΤΑ - Άλλες Πληροφορίες", 
    },
    NewUser: {
      welcomeTitle: "Καλώς ήρθατε στο eWill",
      subtitle: "Είμαστε εδώ για να σας καθοδηγήσουμε στη διαδικασία.",
      description1: "Για να ξεκινήσουμε, θα σας κάνουμε 9 απλές ερωτήσεις για να κατανοήσουμε καλύτερα τις ανάγκες σας.",
      description2: "Βάσει των απαντήσεών σας, θα καθορίσουμε τον καλύτερο τρόπο για να προχωρήσουμε με τη διαθήκη σας—είτε διαδικτυακά, μέσω τηλεφώνου ή με προσωπική συνάντηση.",
      startButton: "Ξεκινήστε τώρα",
    },
    NewQuiz: {
      quizComplete: 'Ολοκλήρωση Κουίζ',
      thankYouMessage: 'Ευχαριστούμε που ολοκληρώσατε το κουίζ μας.',
      consultantMessageBasedOnAnswers: 'Με βάση τις απαντήσεις σας, συνιστούμε να μιλήσετε με έναν σύμβουλο για τους εξής λόγους:',
      consultantMessage1: 'Ένας από τους συμβούλους μας θα επικοινωνήσει μαζί σας για να συζητήσει τις μοναδικές σας περιστάσεις.',
      consultantMessage2: 'Αυτή είναι μια δωρεάν, χωρίς υποχρέωση κλήση, που διαρκεί όχι περισσότερο από 5 λεπτά.',
      consultantMessage3: 'Παρακαλούμε υποβάλετε τον αριθμό τηλεφώνου σας και την προτιμώμενη ώρα για να σας καλέσουμε.',
      nameLabel: 'Όνομα*:',
      emailLabel: 'Email*:',
      phoneLabel: 'Τηλέφωνο*:',
      commentLabel: 'Σχόλιο:',
      senderEmailLabel: 'Email Αποστολέα*:',
      submitButton: 'Υποβολή',
      thankYouTitle: 'Ευχαριστούμε',
      startWillNow: 'Ξεκινήστε τη διαθήκη σας τώρα!',
      enterNamePlaceholder: 'Εισάγετε το όνομά σας',
      enterEmailPlaceholder: 'Εισάγετε το email σας',
      enterPhonePlaceholder: 'Εισάγετε τον αριθμό τηλεφώνου σας',
      enterCommentPlaceholder: 'Εισάγετε πρόσθετα σχόλια',
      enterSenderEmailPlaceholder: 'Εισάγετε το email αποστολέα',
      selectCountryLabel: 'Επιλέξτε τη χώρα σας',
      closeButton: 'Κλείσιμο'
    },
    NewQuizquestions: {
      q1: "Έχετε σύντροφο;",
      q2: "Έχετε παιδιά;",
      q3: "Έχετε παιδιά από προηγούμενο γάμο;",
      q4: "Έχετε ακίνητη περιουσία;",
      q5: "Είστε ιδιοκτήτης επιχείρησης ή διευθυντής/μέτοχος εταιρείας;",
      q6: "Έχετε στεγαστικό δάνειο/χρέος ή υποχρέωση;",
      q7: "Ζείτε στην Κύπρο;",
      q8: "Όλη η περιουσία σας βρίσκεται στην Κύπρο;"
    },
    NewQuizfeedbackReasons: {
      businessOwnerOrComplicated: 'δηλώσατε ότι είστε ιδιοκτήτης επιχείρησης ή έχετε μια περίπλοκη κατάσταση',
      notInCyprus: 'δηλώσατε ότι δεν διαμένετε στην Κύπρο',
      nonCyprusResidence: 'η μόνιμη κατοικία σας δεν βρίσκεται στην Κύπρο',
    },
    quizzes: {
      q1_1: {
        fullName: {
          question: "Ποιο είναι το πλήρες όνομά σας;",
          label: "Πλήρες Όνομα",
          placeholder: "Εισάγετε το πλήρες όνομά σας",
          help: "Παρακαλούμε παρέχετε το πλήρες νομικό σας όνομα.",
        },
        email: {
          question: "Ποια είναι η διεύθυνση email σας;",
          label: "Διεύθυνση Email",
          placeholder: "Εισάγετε τη διεύθυνση email σας",
          help: "Δεν θα μοιραστούμε ποτέ το email σας με κανέναν.",
        },
        dob: {
          question: "Ποια είναι η ημερομηνία γέννησής σας;",
          label: "Ημερομηνία Γέννησης",
          placeholder: "Επιλέξτε την ημερομηνία γέννησής σας",
          help: "Παρέχετε την ημερομηνία γέννησής σας.",
        },
        idPassport: {
          question: "Ποιος είναι ο αριθμός της ταυτότητας ή του διαβατηρίου σας;",
          label: "Αριθμός Ταυτότητας/Διαβατηρίου",
          placeholder: "Εισάγετε τον αριθμό της ταυτότητας ή του διαβατηρίου σας",
          help: "Παρακαλούμε εισάγετε τον αριθμό της ταυτότητας ή του διαβατηρίου σας.",
        },
        address: {
          question: "Ποια είναι η διεύθυνσή σας;",
          label: "Διεύθυνση",
          placeholder: "Εισάγετε τη διεύθυνσή σας",
          help: "Παρακαλούμε παρέχετε την τρέχουσα διεύθυνση κατοικίας σας.",
        },
        gender: {
          question: "Ποιο είναι το φύλο σας;",
          label: "Φύλο",
          placeholder: "Επιλέξτε το φύλο σας",  // Added placeholder
          options: ["Άνδρας", "Γυναίκα", "Άλλο"],
          help: "Επιλέξτε το φύλο σας.",
        },
        phoneNumber: {
          question: "Ποιος είναι ο αριθμός τηλεφώνου σας;",
          label: "Αριθμός Τηλεφώνου",
          placeholder: "Εισάγετε τον αριθμό τηλεφώνου σας",
          help: "Παρακαλούμε εισάγετε έγκυρο αριθμό τηλεφώνου.",
        },
        maritalStatus: {
          question: "Ποια είναι η οικογενειακή σας κατάσταση;",
          label: "Οικογενειακή Κατάσταση",
          placeholder: "Επιλέξτε την τρέχουσα οικογενειακή σας κατάσταση",  // Added placeholder
          options: ["Άγαμος", "Έγγαμος", "Διαζευγμένος", "Χήρος"],
          help: "Επιλέξτε την τρέχουσα οικογενειακή σας κατάσταση.",
        },
        country: {
          question: "Ποια είναι η τρέχουσα τοποθεσία σας;",
          label: "Τρέχουσα Τοποθεσία",
          placeholder: "Επιλέξτε τη χώρα σας",
          help: "Παρακαλούμε επιλέξτε την τρέχουσα χώρα σας.",
        },
      },
      q2_1: {
        // Add questions for the second quiz here
        question1: {
          question: "Ερώτηση 1 για το Κουίζ 2",
          label: "Ερώτηση 1",
          placeholder: "Εισάγετε την απάντησή σας",
          help: "Βοηθητικό κείμενο για την ερώτηση 1 του κουίζ 2.",
        },
      },
    },
  },
};

const i18n = createI18n({
  legacy: false, // Disable legacy mode
  locale: 'en', // Set default locale
  fallbackLocale: 'en', // Set fallback locale
  messages, // Set locale messages
});

export default i18n;
