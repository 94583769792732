<template>
  <div class="verify-email-container">
    <h2>Please verify your email</h2>
    <p>We've sent a verification email to your inbox. Please check your email and click on the verification link to proceed.</p>
    <button @click="resendVerificationEmail">Resend Verification Email</button>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { auth } from '../firebase/config';
import { sendEmailVerification } from 'firebase/auth';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const router = useRouter();
    const intervalId = ref(null);

    const resendVerificationEmail = async () => {
      const user = auth.currentUser;
      if (user) {
        await sendEmailVerification(user);
        alert('Verification email resent. Please check your inbox.');
      }
    };

    const checkEmailVerified = async () => {
      const user = auth.currentUser;
      if (user) {
        await user.reload();
        if (user.emailVerified) {
          clearInterval(intervalId.value);
          router.push('/MyProfile');
        }
      }
    };

    onMounted(() => {
      intervalId.value = setInterval(checkEmailVerified, 3000); // Check every 3 seconds
    });

    onBeforeUnmount(() => {
      if (intervalId.value) {
        clearInterval(intervalId.value);
      }
    });

    return { resendVerificationEmail };
  }
};
</script>

<style scoped>
.verify-email-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #71b7e6;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #9b59b6;
}
</style>
