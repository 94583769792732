<template>
  <v-app>
    <v-container class="main-container">
      <div class="question-container">
        <div class="question-frame" v-if="currentQuestion">
          <div class="header-container">
            <!-- Back Button -->
            <button 
              @click="goBack" 
              class="nav-button back-button" 
              :disabled="currentQuestionIndex === 0"
            >
              <i class="fas fa-arrow-left"></i>
            </button>

            <!-- Progress Indicator -->
            <div class="progress-indicator">
              <div
                v-for="(question, index) in questions"
                :key="index"
                :class="['step', { completed: answers[index] !== undefined, active: currentQuestionIndex === index }]"
                @click="navigateToQuestion(index)"
              >
                {{ index + 1 }}
              </div>
            </div>

            <!-- Next Button -->
            <button 
              @click="goForward" 
              class="nav-button next-button" 
              :disabled="currentQuestionIndex === questions.length - 1"
            >
              <i class="fas fa-arrow-right"></i>
            </button>
          </div>

          <div class="question-content">
            <p>{{ currentQuestion.question }}</p>

            <div v-if="currentQuestion.type === 'yn'" class="button-container yn">
              <button 
                v-for="option in currentQuestion.options" 
                :key="option" 
                @click="answerAndProceed(option)" 
                class="answer-button yn-button"
                :class="{ answered: answers[currentQuestionIndex] === option }"
              >
                {{ option }}
              </button>
            </div>

            <div v-else-if="currentQuestion.type === 'freeTextChars'" class="input-container">
              <input 
                type="text" 
                v-model="textAnswer" 
                placeholder="Enter your text" 
                class="text-input">
              <button @click="submitFreeText()" class="submit-button">{{ $t('NewQuiz.submitButton') }}</button>
            </div>

            <div v-else-if="currentQuestion.type === 'countrySelect'" class="country-container">
              <v-select
                v-model="textAnswer"
                :items="countries"
                :label="$t('NewQuiz.selectCountryLabel')"
                class="select-input"
                dense
                outlined
                style="width: 100%; max-width: 300px;"
              ></v-select>
              <button @click="submitFreeText()" class="submit-button">{{ $t('NewQuiz.submitButton') }}</button>
            </div>
          </div>
        </div>
      </div>
      <!-- Completion and Consultant Feedback Section -->
      <div v-if="currentQuestionIndex >= questions.length" class="completion-container">
        <h2>{{ $t('NewQuiz.quizComplete') }}</h2>
        <div v-if="consultantNeeded">
          <p>{{ $t('NewQuiz.thankYouMessage') }}</p>
          <p>{{ $t('NewQuiz.consultantMessageBasedOnAnswers') }}</p>
          <ul>
            <li v-for="reason in feedbackReasons" :key="reason" class="feedback-reason">{{ reason }}</li>
          </ul>
          <p class="important-note">{{ $t('NewQuiz.consultantMessage2') }}</p>
          <p>{{ $t('NewQuiz.consultantMessage3') }}</p>

          <form @submit.prevent="validateContact" class="contact-form">
            <div class="input-group">
              <div class="input-row">
                <label>{{ $t('NewQuiz.nameLabel') }}</label>
                <input type="text" v-model="name" :placeholder="$t('NewQuiz.enterNamePlaceholder')" class="input-field">
              </div>
              <div class="input-row">
                <label>{{ $t('NewQuiz.emailLabel') }}</label>
                <input type="email" v-model="email" :placeholder="$t('NewQuiz.enterEmailPlaceholder')" class="input-field">
              </div>
              <div class="input-row">
                <label>{{ $t('NewQuiz.phoneLabel') }}</label>
                <input type="text" v-model="phoneNumber" :placeholder="$t('NewQuiz.enterPhonePlaceholder')" class="input-field">
              </div>
              <div class="input-row">
                <label>{{ $t('NewQuiz.commentLabel') }}</label>
                <input type="text" v-model="comment" :placeholder="$t('NewQuiz.enterCommentPlaceholder')" class="input-field">
              </div>
              <div class="input-row">
                <label>{{ $t('NewQuiz.senderEmailLabel') }}</label>
                <input type="email" v-model="emailUser" :placeholder="$t('NewQuiz.enterSenderEmailPlaceholder')" class="input-field">
              </div>
            </div>
            <button type="submit" class="submit-button">{{ $t('NewQuiz.submitButton') }}</button>
            <p v-if="validationError" class="error-text">{{ validationError }}</p>
          </form>
        </div>
        <div v-else>
          <p>{{ $t('NewQuiz.consultantMessage1') }}</p>
          <br>
          <p>{{ $t('NewQuiz.consultantMessage2') }}</p>
          <br>
          <h3 class="highlighted-text">{{ $t('NewQuiz.startWillNow') }}</h3>
          <br>
          <component :is="loginComponent"></component>
        </div>
      </div>

      <!-- Thank You Modal -->
      <v-dialog v-model="showThankYou" max-width="500">
        <v-card>
          <v-card-title class="headline">{{ $t('NewQuiz.thankYouTitle') }}</v-card-title>
          <v-card-text>
            {{ $t('NewQuiz.thankYouMessage') }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="showThankYou = false">{{ $t('NewQuiz.closeButton') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-app>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import emailValidator from 'email-validator';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import axios from 'axios';
import '/src/assets/css/styles.css';

export default {
  data() {
    return {
      name: '',
      email: '',
      phoneNumber: '',
      comment: '',
      emailUser: '',
      validationError: '',
      currentQuestionIndex: 0,
      answers: [],
      questions: [
        { question: this.$t('NewQuizquestions.q1'), options: ["No", "Yes", "It's Complicated"], type: 'yn' },
        { question: this.$t('NewQuizquestions.q2'), options: ["No", "Yes"], type: 'yn' },
        { question: this.$t('NewQuizquestions.q3'), options: ["No", "Yes"], type: 'yn' },
        { question: this.$t('NewQuizquestions.q4'), options: ["No", "Yes"], type: 'yn' },
        { question: this.$t('NewQuizquestions.q5'), options: ["No", "Yes"], type: 'yn' },
        { question: this.$t('NewQuizquestions.q6'), options: ["No", "Yes"], type: 'yn' },
        { question: this.$t('NewQuizquestions.q7'), options: ["No", "Yes"], type: 'yn' },
        { question: this.$t('NewQuizquestions.q8'), options: ["No", "Yes"], type: 'yn' },
        //{ question: this.$t('NewQuizquestions.q9'), options: [], type: 'countrySelect' },
      ],
      consultantNeeded: false,
      feedbackReasons: [],
      countries: [],
      loginComponent: null,
      showThankYou: false,
      textAnswer: '',
    };
  },
  computed: {
    currentQuestion() {
      return this.questions.length > 0 ? this.questions[this.currentQuestionIndex] : null;
    }
  },
  methods: {
    answerAndProceed(answer) {
      this.answers[this.currentQuestionIndex] = answer;
      if (this.currentQuestionIndex < this.questions.length - 1) {
        this.goForward();
      } else {
        this.evaluateAnswers();
      }
    },
    submitFreeText() {
      if (this.textAnswer) {
        this.answerAndProceed(this.textAnswer);
        this.textAnswer = '';
      } else {
        this.validationError = 'Please enter your answer.';
      }
    },
    goBack() {
      if (this.currentQuestionIndex > 0) {
        this.currentQuestionIndex--;
      }
    },
    goForward() {
      if (this.currentQuestionIndex < this.questions.length - 1) {
        this.currentQuestionIndex++;
      }
    },
    navigateToQuestion(index) {
      this.currentQuestionIndex = index;
    },
    evaluateAnswers() {
      this.feedbackReasons = [];

      for (let i = 0; i < this.answers.length; i++) {
        const answer = this.answers[i];
        const question = this.questions[i]?.question;

        if ((answer === 'Yes' && (question.includes('business owner') || question.includes('company'))) ||
            (answer === "It's Complicated" && question.includes('partner'))) {
          this.feedbackReasons.push(this.$t('NewQuizfeedbackReasons.businessOwnerOrComplicated'));
        } else if (answer === 'No' && question.includes('Is everything you own in Cyprus?')) {
          this.feedbackReasons.push(this.$t('NewQuizfeedbackReasons.notInCyprus'));
        } else if (question === this.$t('NewQuizquestions.q9') && answer !== "Cyprus") {
          this.feedbackReasons.push(this.$t('NewQuizfeedbackReasons.nonCyprusResidence'));
        }
      }

      this.consultantNeeded = this.feedbackReasons.length > 0;

      if (this.consultantNeeded) {
        this.currentQuestionIndex++; // Move to the next step to show the feedback and contact form
      } else {
        this.loadLoginComponent();
      }
    },

    async fetchCountries() {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        const countries = await response.json();
        this.countries = countries.map(country => country.name.common).sort();
        const countryQuestion = this.questions.find(q => q.type === 'countrySelect');
        if (countryQuestion) {
          countryQuestion.options = this.countries;
        }
      } catch (error) {
        console.error("Failed to fetch countries:", error);
      }
    },
    async loadLoginComponent() {
      this.loginComponent = defineAsyncComponent(() =>
        import('/src/components/LoginForm.vue')
      );
    },
    async validateContact() {
      this.validationError = '';
      if (!this.name) {
        this.validationError = 'Please enter your name.';
        return;
      }
      if (!emailValidator.validate(this.email)) {
        this.validationError = 'Please enter a valid email address.';
        return;
      }
      if (!emailValidator.validate(this.emailUser)) {
        this.validationError = 'Please enter a valid sender email address.';
        return;
      }
      const phoneNumber = parsePhoneNumberFromString(this.phoneNumber);
      if (!phoneNumber || !phoneNumber.isValid()) {
        this.validationError = 'Please enter a valid phone number.';
        return;
      }

      try {
        const response = await axios.post('https://europe-west1-ewill-d6f34.cloudfunctions.net/sendEmail', {
          userName: this.name,
          userEmail: this.email,
          userPhoneNumber: this.phoneNumber,
          comment: this.comment,
          emailUser: this.emailUser,
        });
        console.log('Email notification sent:', response.data);
        this.showThankYou = true;
      } catch (error) {
        console.error('Error sending email notification:', error);
      }
    }
  },
  created() {
    this.fetchCountries();
  }
};
</script>

<style scoped>
.main-container {
  justify-content: center;
  align-items: flex-start;
  padding-top: 10vh; 
  height: 100vh;
}

.question-frame {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.header-container {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-button {
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #007891;
}

.nav-button:disabled {
  color: #ccc;
  cursor: not-allowed;
}

.progress-indicator {
  display: flex;
  align-items: center;
  margin: 0 20px;
  flex-grow: 1;
  justify-content: space-around;
}

.step {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e0e0e0;
  cursor: pointer;
}

.step.completed {
  background-color: #4fb7cb;
  color: white;
}

.step.active {
  background-color: #007891;
  color: white;
}

.answer-button {
  padding: 10px 10px;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin: 5px;
}

p {
  font-size: 1.0rem;
  margin-bottom: 20px;
}

.answer-button:hover {
  transform: translateY(-3px);
}

.answer-button.answered {
  background-color: #4fb7cb;
}

.v-dialog .v-card {
  max-width: 500px;
}

.completion-container {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.completion-container h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.completion-container p {
  font-size: 16px;
  color: #555;
  line-height: 1.5;
}

.completion-container .highlighted-text {
  font-size: 18px;
  font-weight: bold;
  color: #007bff;

}

.contact-form .input-group {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column; /* Make the input group stack vertically */
}

.contact-form .input-group .input-row {
  margin-bottom: 15px;
}

.contact-form .input-group .input-field {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.contact-form .submit-button {
  padding: 10px 20px;
  background-color: #007891;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.contact-form .submit-button:hover {
  background-color: #0056b3;
}

.contact-form .error-text {
  color: red;
  margin-top: 10px;
  font-weight: bold;
}

/* Feedback reason styling */
.feedback-reason {
  font-style: italic;
  font-size: 0.9rem; /* Slightly smaller font size */
  margin-bottom: 10px;
}

.important-note {
  font-weight: bold;
  color: #d9534f;
  position: relative;
  display: inline-block;
}

.important-note {
  font-weight: bold;
  color: #d9534f;
  background-color: #f8d7da;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #d9534f;
  animation: fade 3s infinite;
}

@keyframes fade {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}



</style>