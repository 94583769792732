<template>
  <div class="panel panel-default">
    <div class="panel-heading">{{ quizTitle }}</div>
    <div class="panel-body">
      <div class="question-container">
        <div class="question-frame">
          <h3>YOUR FUNERAL WISHES</h3>
          <p>Plan your funeral, helping your family answer these questions when the time comes</p>
          <br>
          <template v-for="(question, index) in questions" :key="index">
            <h4>{{ question.question }}</h4>
            <div v-if="question.type === 'dynamicList'" class="dynamic-list-container">
              <div v-for="(entry, entryIndex) in answers[question.key]" :key="'entry-' + entryIndex" class="entry">
                <div class="input-group">
                  <div v-for="field in question.fields" :key="field.name" class="input-container">
                    <input
                      :type="field.type"
                      v-model="entry[field.name]"
                      @input="handleDynamicListChange(question.key, entryIndex, field.name, entry[field.name])"
                      required
                    />
                    <label v-if="!entry[field.name]" class="input-label">{{ field.label }}</label>
                  </div>
                  <button @click="removeEntry(question.key, entryIndex)" class="remove-button">−</button>
                </div>
              </div>

              <div class="add-asset-button-container">
                <p class="add-button-text material-text">Please click the button to add a person</p>
                <button @click="addEntry(question.key, question.fields)" class="new-add-button">
                  <span class="material-icons">add</span> Add person
                </button>
              </div>

            </div>
            <div v-if="question.type === 'freeTextChars' && !question.condition" class="input-container">
              <textarea
                v-model="answers[question.key]"
                @input="handleAnswerChange(question.key, $event.target.value)"
                class="text-input"
                rows="4"
              ></textarea>
              <label v-if="!answers[question.key]" class="input-label">{{ question.question }}</label>
            </div>
            <div v-if="question.type === 'yn'" class="button-container yn">
              <button
                v-for="option in ['YES', 'NO']"
                :key="option"
                @click="handleAnswerChange(question.key, option)"
                :class="{
                  'selected-option': answers[question.key] === option,
                  'answered-option': answers[question.key],
                  'non-answered-option': !answers[question.key]
                }"
                class="answer-button yn-button"
              >
                {{ option }}
              </button>
            </div>
            <div v-if="question.type === 'freeTextChars' && question.condition && answers[question.condition.key] === question.condition.value" class="input-container">
              <textarea
                v-model="answers[question.key]"
                @input="handleAnswerChange(question.key, $event.target.value)"
                class="text-input"
                rows="4"
              ></textarea>
              <label v-if="!answers[question.key]" class="input-label">{{ question.question }}</label>
            </div>
          </template>
          <br />
          <div class="navigation-buttons">
            <button @click="saveAndContinue" class="next-button">Save and Continue</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>

import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { db } from '../firebase/config';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import getUser from '@/composables/getUser';
import { q4, q4_title } from '@/data/quizQuestions';
import '/src/assets/css/styles.css';

export default {
  setup() {
    const router = useRouter();
    const { user } = getUser();
    const userId = user.value.uid;
    const quizId = 'q4';

    const quizTitle = q4_title;
    const questions = q4;

    const answers = ref({
      funeralResponsibles: [],
      finalRestingPlace: '',
      leaveMessage: '',
      messageContent: ''
    });

    const handleAnswerChange = (key, value) => {
      answers.value[key] = value;
      const docRef = doc(db, `userAnswers/${userId}/${quizId}/data`);
      setDoc(docRef, { [key]: value }, { merge: true })
        .then(() => console.log(`Updated Firestore with key: ${key} and value: ${value}`))
        .catch(error => console.error("Failed to update Firestore:", error));
    };

    const handleDynamicListChange = (key, index, field, value) => {
      answers.value[key][index][field] = value;
      const docRef = doc(db, `userAnswers/${userId}/${quizId}/data`);
      setDoc(docRef, { [key]: answers.value[key] }, { merge: true })
        .then(() => console.log(`Updated Firestore for ${key}`))
        .catch(error => console.error("Failed to update Firestore:", error));
    };

    const addEntry = (key, fields) => {
      if (!answers.value[key]) {
        answers.value[key] = [];
      }
      const newEntry = {};
      fields.forEach(field => {
        newEntry[field.name] = '';
      });
      answers.value[key].push(newEntry);
    };

    const removeEntry = (key, index) => {
      answers.value[key].splice(index, 1);
      const docRef = doc(db, `userAnswers/${userId}/${quizId}/data`);
      setDoc(docRef, { [key]: answers.value[key] }, { merge: true })
        .then(() => console.log(`Updated Firestore for ${key}`))
        .catch(error => console.error("Failed to update Firestore:", error));
    };

    const saveAndContinue = () => {
      console.log('Answers:', answers.value);
      router.push('/MyProfile'); // Adjust as necessary to match your app's routing
    };

    onMounted(() => {
      fetchAnswers();
    });

    const fetchAnswers = async () => {
      try {
        const docRef = doc(db, `userAnswers/${userId}/${quizId}/data`);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          Object.assign(answers.value, docSnap.data());
        }
      } catch (error) {
        console.error("Error fetching answers from Firestore:", error);
      }
    };

    return {
      answers,
      handleAnswerChange,
      handleDynamicListChange,
      addEntry,
      removeEntry,
      saveAndContinue,
      questions,
      quizTitle
    };
  }
};

</script>


<style scoped>
/* Add these classes to your global CSS */

.input-container {
  position: relative;
  margin-top: 20px;
  border: 1px solid #ccc; /* Add border */
  border-radius: 4px; /* Rounded corners */
  background-color: #fff; /* White background */
  box-sizing: border-box;
  padding: 10px; 
  display: flex;
  width: 100%;
  font-size: 14px;
}

.input-label {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 14px;
  color: #aaa;
  pointer-events: none;
  transition: 0.2s ease all;
}

.text-input {
  display: flex;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
}

.text-input:focus + .input-label,
.text-input:not(:placeholder-shown) + .input-label {
  top: -20px;
  left: 10px;
  font-size: 12px;
  color: #009AB3;
}

.button-container.yn,
.button-container.multiple {
  display: flex;
  justify-content: space-around; 
  gap: 10px;
}

.button-container.multiple {
  flex-wrap: wrap;
  justify-content: flex-start;
}

.next-button {
  background-color: #009AB3;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
}

.navigation-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.dynamic-list-container {
  margin-top: 10px;
}

.input-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.remove-button {
  background-color: #009AB3;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.remove-button:hover {
  background-color: #007891;
}

.add-button {
  background-color: #009AB3;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 10px;
}

.add-button:hover {
  background-color: #007891;
}

/* Other existing styles */
</style>
