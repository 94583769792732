<template>
    <div v-if="!consentGiven" class="gdpr-consent">
      <div class="gdpr-message">
        <p>
          We use cookies to ensure you get the best experience on our website. By continuing to use this site, you consent to our use of cookies and our Privacy Policy.
          <a href="/privacy-policy" target="_blank">Learn more</a>.
        </p>
        <div class="gdpr-buttons">
          <button @click="giveConsent">Accept</button>
          <button @click="rejectConsent">Decline</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        consentGiven: false
      };
    },
    mounted() {
      this.checkConsent();
    },
    methods: {
      checkConsent() {
        const consent = localStorage.getItem('gdprConsent');
        if (consent) {
          this.consentGiven = true;
        }
      },
      giveConsent() {
        localStorage.setItem('gdprConsent', 'true');
        this.consentGiven = true;
      },
      rejectConsent() {
        localStorage.setItem('gdprConsent', 'false');
        this.consentGiven = true; // Hide the message even if they reject
      }
    }
  };
  </script>
  
  <style scoped>
  .gdpr-consent {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 15px;
    z-index: 1000;
  }
  
  .gdpr-message {
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .gdpr-message p {
    margin: 0 0 10px;
  }
  
  .gdpr-buttons {
    display: flex;
    gap: 10px;
  }
  
  .gdpr-buttons button {
    background-color: #007891;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .gdpr-buttons button:hover {
    background-color: #005f6b;
  }
  </style>
  