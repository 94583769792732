<template>
  <div class="panel panel-default">
    <div class="panel-heading">{{ q1_2_title }}</div>
    <div class="panel-body">
      <div class="question-container">
        <div v-if="questions.length > 0" class="question-frame">
          <div v-for="(question, index) in displayedQuestions" :key="question.key" class="question-block">
            <p class="question-text">{{ getQuestionNumber(index) }}. {{ question.question }}</p>

            <!-- Multiple Answers questions -->
            <div v-if="question.type === 'multipleAnswers'" class="dynamic-list-container">
              <div class="input-group">
                <div v-for="field in question.fields" :key="field.name" class="input-container">
                  <input
                    :type="field.type"
                    v-model="answers[question.key][field.name]"
                    @input="handleMultipleAnswersChange(question.key, field.name, answers[question.key][field.name])"
                    required
                  />
                  <label class="floating-label">{{ field.label }}</label>
                </div>
              </div>
            </div>

            <!-- Dynamic List questions -->
            <div v-else-if="question.type === 'dynamicList'" class="dynamic-list-container">
              <div v-for="(entry, entryIndex) in answers[question.key]" :key="entryIndex" class="entry">
                <div class="input-group">
                  <div v-for="field in question.fields" :key="field.name" :class="{'input-container': field.type !== 'dropdown', 'dropdown-container': field.type === 'dropdown'}">
                    <template v-if="field.type === 'dropdown'">
                      <select v-model="entry[field.name]" @change="handleDynamicListChange(question.key, entryIndex, field.name, entry[field.name])" class="dropdown" required>
                        <option v-for="option in field.options" :key="option" :value="option">{{ option }}</option>
                      </select>
                      <label class="floating-label">{{ field.label }}</label>
                    </template>
                    <template v-else>
                      <input
                        :type="field.type"
                        v-model="entry[field.name]"
                        @input="handleDynamicListChange(question.key, entryIndex, field.name, entry[field.name])"
                        required
                      />
                      <label class="floating-label">{{ field.label }}</label>
                    </template>
                  </div>
                  <button @click="removeEntry(question.key, entryIndex)" class="remove-button">−</button>
                </div>
              </div>
              <button @click="addEntry(question.key, question.fields)" class="add-button">+</button>
            </div>

            <!-- Other input types -->
            <div v-else-if="question.type === 'email'" class="input-container">
              <input type="email" v-model="answers[question.key]" @input="handleAnswerChange(question.key, $event.target.value)" required>
              <label class="floating-label">Email</label>
              <p v-if="!isEmailValid(answers[question.key]) && answers[question.key]" class="error-message">
                Please enter a valid email address.
              </p>
            </div>

            <!-- Phone number input with validation -->
            <div v-if="question.type === 'phoneNumber'" class="input-container">
              <input type="tel" v-model="answers[question.key]" @input="handleAnswerChange(question.key, $event.target.value)" required>
              <label class="floating-label">Phone Number</label>
              <p v-if="!isPhoneNumberValid(answers[question.key]) && answers[question.key]" class="error-message">
                Please enter a valid phone number (e.g., +357 99 xx xx xx).
              </p>
            </div>

            <!-- Yes/No questions -->
            <div v-if="question.type === 'yn'" class="button-container yn">
              <button v-for="option in ['Yes', 'No']" :key="option" @click="handleAnswerChange(question.key, option)" :class="{'selected-option': answers[question.key] === option, 'answered-option': answers[question.key], 'non-answered-option': !answers[question.key]}" class="answer-button yn-button">
                {{ option }}
              </button>
            </div>

            <!-- Free text input (characters) -->
            <div v-else-if="question.type === 'freeTextChars'" class="input-container">
              <input type="text" v-model="answers[question.key]" @input="handleAnswerChange(question.key, $event.target.value)" required>
              <label class="floating-label">Type your answer here...</label>
            </div>

            <!-- Free text input (numbers) -->
            <div v-else-if="question.type === 'freeTextNumbers'" class="input-container">
              <input type="number" v-model="answers[question.key]" @input="handleAnswerChange(question.key, $event.target.value)" required>
              <label class="floating-label">Type your answer here...</label>
            </div>

            <!-- Date input -->
            <div v-else-if="question.type === 'date'" class="input-container"> 
              <input type="date" v-model="answers[question.key]" @input="handleAnswerChange(question.key, $event.target.value)" required>
              <label class="floating-label">Select a date</label>
            </div>

            <!-- Multiple choice questions -->
            <div v-else-if="question.type === 'multiple'" class="button-container multiple">
              <button v-for="option in question.options" :key="option" @click="handleAnswerChange(question.key, option)" :class="{'selected-option': answers[question.key] === option, 'answered-option': answers[question.key], 'non-answered-option': !answers[question.key]}" class="answer-button multiple-choice-button">
                {{ option }}
              </button>
            </div>
          </div>

          <!-- Navigation buttons -->
          <div class="navigation-buttons" v-if="isMobile">
            <button @click="goBack" class="back-button" :disabled="currentPage <= 0">← Back</button>
            <button @click="nextPage" class="next-button">Next →</button>
          </div>
          <div v-else>
            <button @click="saveAndContinue" class="next-button">Save and Continue</button>
          </div>
        </div>
        <div v-else>
          <h2>Quiz Complete</h2>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import { db } from '../firebase/config'; 
import { doc, setDoc, getDoc, deleteDoc, updateDoc, arrayRemove } from 'firebase/firestore';
import getUser from '@/composables/getUser';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { q1_2_title, q1_2 } from '@/data/quizQuestions';  // Importing all question sets
import '/src/assets/css/styles.css';

export default {
  setup() {
    const router = useRouter();
    const { user } = getUser();
    const userId = user.value.uid;  

    const questions = q1_2; 

    const answers = ref({});

    const currentPage = ref(0);
    const windowWidth = ref(window.innerWidth);
    const isMobile = computed(() => windowWidth.value < 768);
    const questionsPerPage = computed(() => isMobile.value ? 1 : questions.value.length);
    const totalPages = computed(() => Math.ceil(questions.value.length / questionsPerPage.value));
    const displayedQuestions = computed(() => {
      const start = currentPage.value * questionsPerPage.value;
      const end = start + questionsPerPage.value;
      return questions.value.filter(question => {
        if (!question.condition) {
          return true;
        }
        const { key, value } = question.condition;
        return answers.value[key] === value;
      }).slice(start, end);
    });
    const getQuestionNumber = index => currentPage.value * questionsPerPage.value + index + 1;

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    const isPhoneNumberValid = phoneNumber => {
      try {
        const number = parsePhoneNumberFromString(phoneNumber, 'CY');
        return number.isValid();
      } catch (error) {
        return false;
      }
    };

    const isEmailValid = email => {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    };

    onMounted(() => {
      window.addEventListener('resize', handleResize);
      initializeAnswers();
      fetchAnswers();
    });

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize);
    });

    const goBack = () => {
      if (currentPage.value > 0) currentPage.value--;
    };

    const nextPage = () => {
      if (currentPage.value < totalPages.value - 1) {
        currentPage.value++;
      } else {
        submitQuiz();
      }
    };

    const submitQuiz = () => {
      console.log('Quiz submitted with answers:', answers.value);
      router.push('/MyProfile');
    };

    const handleAnswerChange = (questionKey, value) => {
      answers.value[questionKey] = value;
      const docRef = doc(db, `userAnswers/${userId}/q1_2/${questionKey}`);
      setDoc(docRef, { answer: value }, { merge: true })
        .then(() => console.log(`Updated Firestore with key: ${questionKey}`))
        .catch(error => console.error("Failed to update Firestore:", error));
    };

    const handleMultipleAnswersChange = (questionKey, fieldName, value) => {
      if (!answers.value[questionKey]) {
        answers.value[questionKey] = {};
      }
      answers.value[questionKey][fieldName] = value;
      const docRef = doc(db, `userAnswers/${userId}/q1_2/${questionKey}`);
      setDoc(docRef, { answer: answers.value[questionKey] }, { merge: true })
        .then(() => console.log(`Updated Firestore with key: ${questionKey}`))
        .catch(error => console.error("Failed to update Firestore:", error));
    };

    const handleDynamicListChange = (questionKey, entryIndex, field, value) => {
      answers.value[questionKey][entryIndex][field] = value;
      const docRef = doc(db, `userAnswers/${userId}/q1_2/${questionKey}`);
      setDoc(docRef, { answer: answers.value[questionKey] }, { merge: true })
        .then(() => console.log(`Updated Firestore with key: ${questionKey}`))
        .catch(error => console.error("Failed to update Firestore:", error));
    };

    const fetchAnswers = async () => {
      for (const question of questions.value) {
        const docRef = doc(db, `userAnswers/${userId}/q1_2/${question.key}`);
        try {
          const docSnap = await getDoc(docRef);
          if (docSnap.exists() && docSnap.data().answer) {
            if (question.type === 'dynamicList') {
              answers.value[question.key] = docSnap.data().answer.map(entry => ({ ...entry }));
            } else if (question.type === 'multipleAnswers') {
              if (!answers.value[question.key]) {
                answers.value[question.key] = {};
              }
              Object.assign(answers.value[question.key], docSnap.data().answer);
            } else {
              answers.value[question.key] = docSnap.data().answer;
            }
            console.log(`Loaded answer for ${question.key}:`, docSnap.data().answer);
          } else {
            console.log(`No existing answer found for ${question.key}`);
            if (question.type === 'dynamicList') {
              answers.value[question.key] = [];
            } else if (question.type === 'multipleAnswers') {
              answers.value[question.key] = {};
            }
          }
        } catch (error) {
          console.error("Error fetching answer from Firestore for question", question.key, ":", error);
        }
      }
    };

    const initializeAnswers = () => {
      questions.value.forEach((question) => {
        if (question.type === 'multipleAnswers') {
          if (!answers.value[question.key]) {
            answers.value[question.key] = {};
            question.fields.forEach(field => {
              answers.value[question.key][field.name] = '';
            });
          }
        } else if (question.type === 'dynamicList') {
          if (!answers.value[question.key]) {
            answers.value[question.key] = [];
          }
        } else {
          if (!answers.value[question.key]) {
            answers.value[question.key] = '';
          }
        }
      });
    };

    const addEntry = (questionKey, fields) => {
      if (!answers.value[questionKey]) {
        answers.value[questionKey] = [];
      }
      const newEntry = {};
      fields.forEach(field => {
        newEntry[field.name] = '';
      });
      answers.value[questionKey].push(newEntry);
    };

    const removeEntry = async (questionKey, index) => {
      const entries = [...answers.value[questionKey]];
      const entryToRemove = entries.splice(index, 1)[0];
      answers.value[questionKey] = entries;

      const docRef = doc(db, `userAnswers/${userId}/q1_2/${questionKey}`);
      if (entries.length === 0) {
        // If no entries left, delete the document
        await deleteDoc(docRef);
        console.log(`Deleted document as all entries are removed: ${questionKey}`);
      } else {
        // Update the document with the remaining entries
        await updateDoc(docRef, {
          answer: arrayRemove(entryToRemove)
        });
        console.log(`Removed entry from Firestore: ${entryToRemove}`);
      }
    };

    const saveAndContinue = () => {
      console.log('Answers:', answers.value);
      router.push('/MyProfile'); // Adjust as necessary to match your app's routing
    };

    return {
      q1_2_title, questions, answers, currentPage, totalPages, displayedQuestions, isMobile, getQuestionNumber, goBack, nextPage, handleAnswerChange, submitQuiz, isEmailValid, isPhoneNumberValid, addEntry, removeEntry, handleDynamicListChange, handleMultipleAnswersChange, saveAndContinue
    };
  }
}
</script>




<style scoped>
.input-container {
  position: relative;
  margin-top: 20px;
  border: 1px solid #ccc; /* Add border */
  border-radius: 4px; /* Rounded corners */
  background-color: #fff; /* White background */
  box-sizing: border-box;
  padding: 10px; /* Add some padding */
  display: flex;
  width: 100%;
  font-size: 14px;
}

.dropdown {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  appearance: none; /* Remove default dropdown styling */
  -webkit-appearance: none; /* Remove default dropdown styling for Safari */
  -moz-appearance: none; /* Remove default dropdown styling for Firefox */
  position: relative;
  z-index: 1;
}

.dropdown-container {
  width: 100%; /* Ensure the container spans the full width */
  position: relative;
}

.dropdown-container::after {
  content: '▼'; /* Custom arrow icon */
  font-size: 12px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  z-index: 2;
}

.floating-label {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 14px;
  color: #aaa;
  pointer-events: none;
  transition: 0.2s ease all;
}

.input-container input:focus + .floating-label,
.input-container input:not(:placeholder-shown) + .floating-label,
.dropdown:focus + .floating-label,
.dropdown:not(:placeholder-shown) + .floating-label {
  top: -20px;
  left: 10px;
  font-size: 12px;
  color: #009AB3;
}

.dynamic-list-container {
  margin-top: 10px;
}

.input-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.remove-button {
  background-color: #009AB2;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.remove-button:hover {
  background-color: #007891;
}
</style>



