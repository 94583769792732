<template>
  <header :class="{'scrolled-nav': scrolledNav}">
    <nav>
      <div class="branding">
        <router-link to="/">
          <img src="../assets/logo.png" alt="logo" />
        </router-link>
      </div>

      <!-- Main Navigation Links -->
      <ul v-show="!mobile" class="navigation">
        <li><router-link class="link" :to="{name: 'HomeView', hash: '#home'}">{{ $t('menu.home') }}</router-link></li>
        <li><router-link class="link" :to="{name: ''}">{{ $t('menu.about') }}</router-link></li>
        <li><router-link class="link" :to="{ name: 'HomeView', hash: '#services' }">{{ $t('menu.services') }}</router-link></li>
        <li><router-link class="link" :to="{ name: 'HomeView', hash: '#contact' }">{{ $t('menu.contact') }}</router-link></li>
      </ul>

      <!-- Language Switcher -->
      <div v-show="!mobile" class="language-switcher">
        <CountryFlag :country="currentLanguage === 'en' ? 'gb' : 'gr'" size="normal" @click="toggleLanguage" />
      </div>

      <!-- User Profile and Dropdown -->
      <div class="user-info" v-if="user && user.email">
        <div class="dropdown">
          <span class="username">
            <div class="avatar">{{ userInitials }}</div>
            <span class="name">{{ userDisplayName }}</span>
            <i class="fas fa-chevron-down dropdown-arrow"></i>
          </span>
          <div class="dropdown-content">
            <router-link to="/MyProfile" class="nav-link">
              <i class="fas fa-user"></i> Profile
            </router-link>
            <router-link to="/mastros" class="nav-link" v-if="isAdmin">
              <i class="fas fa-user-shield"></i> Admin
            </router-link>
            <button @click="handleLogout" class="nav-link">
              <i class="fas fa-sign-out-alt"></i> Logout
            </button>
          </div>
        </div>
      </div>

      <!-- Login and Sign Up Links, only shown if no user is logged in -->
      <template v-if="!user || !user.email">
        <router-link to="/login" class="text-button">Login</router-link>
        <router-link to="/signup" class="text-button">Sign Up</router-link>
      </template>

      <div class="icon">
        <i @click="toggleMobileNav" v-show="mobile" class="fas fa-bars" :class="{'icon-active': mobileNav}"></i>
      </div>

      <!-- Mobile Navigation -->
      <transition name="mobile-nav">
        <ul v-show="mobileNav" class="dropdown-nav">
          <li class="language-switcher">
            <CountryFlag :country="currentLanguage === 'en' ? 'gb' : 'gr'" size="normal" @click="toggleLanguage" />
          </li>
          <li><router-link class="link" :to="{name: 'HomeView', hash: '#home'}">{{ $t('menu.home') }}</router-link></li>
          <li><router-link class="link" :to="{name: ''}">{{ $t('menu.about') }}</router-link></li>
          <li><router-link class="link" :to="{ name: 'HomeView', hash: '#services' }">{{ $t('menu.services') }}</router-link></li>
          <li><router-link class="link" :to="{ name: 'HomeView', hash: '#contact' }">{{ $t('menu.contact') }}</router-link></li>

        </ul>
      </transition>
    </nav>
  </header>
</template>

<script>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import getUser from '../composables/getUser.js';
import useLogout from '../composables/useLogout.js';
import CountryFlag from 'vue-country-flag-next'; // Ensure this is correctly imported

export default {
  name: "NavigationView",
  components: {
    CountryFlag // Register CountryFlag component
  },
  data() {
    return {
      scrolledNav: null,
      mobile: null,
      mobileNav: null,
      windowWidth: null,
      currentLanguage: this.$i18n.locale,
    };
  },
  
  setup() {
    const router = useRouter();
    const { user } = getUser();
    const { logout, error } = useLogout();

    const adminIDs = ['vKciGuueuSaYGeziGO89h4fwucf2', '1lFGIJIttwTuG1eLbPwfniGKXL52'];
    const isAdmin = computed(() => {
      return user.value && adminIDs.includes(user.value.uid);
    });

    const userDisplayName = computed(() => {
      return user.value?.displayName || user.value?.email.split('@')[0];
    });

    const userInitials = computed(() => {
      const name = userDisplayName.value;
      return name ? name.match(/\b\w/g)?.join('').toUpperCase() : 'U';
    });

    const handleLogout = async () => {
      await logout();
      if (!error.value) {
        router.push('/');
      } else {
        console.error("Logout failed:", error.value);
      }
    };

    return { user, userDisplayName, userInitials, handleLogout, isAdmin };
  },
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  methods: {
    toggleMobileNav() {
      this.mobileNav = !this.mobileNav;
    },
    updateScroll() {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 50) {
        this.scrolledNav = true;
        return;
      }
      this.scrolledNav = false;
    },
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 768) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
    },
    changeLanguage(locale) {
      this.$i18n.locale = locale;
      this.currentLanguage = locale; // Update the currentLanguage variable
    },
    toggleLanguage() {
      const newLanguage = this.currentLanguage === 'en' ? 'gr' : 'en';
      this.changeLanguage(newLanguage);
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  background-color: rgba(202, 218, 228, 0.9);
  z-index: 99;
  width: 100%;
  position: fixed;
  top: 0;
  transition: 0.5s ease all;
  color: #fff;

  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    transition: 0.5s ease all;
    width: 90%;
    margin: 0 auto;
    @media (min-width: 1140px) {
      max-width: 1140px;
    }

    ul,
    .link {
      font-weight: 500;
      color: #557489;
      list-style: none;
      text-decoration: none;
    }

    li {
      text-transform: uppercase;
      padding: 16px;
      margin-left: 16px;
    }

    .link {
      font-size: 14px;
      transition: 0.3s ease all;
      padding-bottom: 4px;
      border-bottom: 2px solid transparent;
      &:hover {
        color: #009ab3;
        border-color: #009ab3;
      }
    }

    .branding img {
      width: 50px;
      transition: 0.5s ease all;
    }

    .user-info {
      display: flex;
      align-items: center;
      position: relative;

      .dropdown {
        position: relative;

        .username {
          display: flex;
          align-items: center;
          color: #557489;
          cursor: pointer;
          transition: color 0.3s;

          &:hover {
            color: #009ab3;
          }

          .avatar {
            width: 32px;
            height: 32px;
            background-color: #009ab3;
            color: #fff;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 8px;
            font-size: 16px;
            font-weight: bold;
          }

          .name {
            font-size: 14px;
            margin-right: 8px;
          }

          .dropdown-arrow {
            font-size: 12px;
          }
        }

        .dropdown-content {
          display: none;
          position: absolute;
          background-color: #fff;
          min-width: 200px;
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
          z-index: 1;
          top: 100%;
          right: 0;
          border-radius: 4px;
          overflow: hidden;

          .nav-link,
          .text-button {
            display: flex;
            align-items: center;
            padding: 12px;
            color: #333;
            text-decoration: none;
            transition: background-color 0.3s;

            i {
              margin-right: 8px;
            }

            &:hover {
              background-color: #f0f0f0;
            }
          }
        }

        &:hover .dropdown-content {
          display: block;
        }
      }
    }

    .auth-links {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    .language-switcher {
      display: flex;
      align-items: center;
      gap: 10px;

      .active-language {
        border: 2px solid #009ab3;
        border-radius: 50%;
      }

      country-flag {
        cursor: pointer;
        width: 24px;
        height: 24px;
        transition: transform 0.3s;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  .navigation {
    display: flex;
    align-items: center;
  }

  .icon {
    display: flex;
    align-items: center;
    font-size: 24px;
    cursor: pointer;
    transition: 0.3s ease;

    i.icon-active {
      transform: rotate(90deg);
    }
  }

  .dropdown-nav {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    max-width: 250px;
    height: 100%;
    background-color: #cadae4;
    top: 0;
    left: 0;
    padding-top: 50px;

    li {
      margin-left: 0;
      .link {
        color: #000;
        &:hover {
          color: #009ab3;
        }
      }
    }

    .language-switcher {
      padding: 10px 16px;

      button {
        color: #000;
      }
    }
  }

  .mobile-nav-enter-active,
  .mobile-nav-leave-active {
    transition: 1s ease all;
  }

  .mobile-nav-enter-from,
  .mobile-nav-leave-to {
    transform: translateX(-250px);
  }

  .mobile-nav-enter-to {
    transform: translateX(0);
  }
}

.scrolled-nav {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.2), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

  nav {
    padding: 8px 0;

    .link {
      color: #fff;

      &:hover {
        color: #00afea;
      }
    }

    .branding img {
      width: 40px;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
  }
}
</style>
