<template>
  <div class="panel panel-default">
    <div class="panel-heading">{{ quizTitle }}</div>
    <div class="panel-body">
      <div class="question-container">
        <div v-if="questions.length > 0" class="question-frame">
          <div v-for="(question, index) in displayedQuestions" :key="question.key" class="question-block">
            <p class="question-text">{{ getQuestionNumber(index) }}. {{ question.question }}</p>

            <!-- Yes/No question -->
            <div v-if="question.type === 'yn'" class="button-container yn">
              <button v-for="option in ['NO', 'YES']" :key="option"
                      @click="handleAnswerChange(question.key, option)"
                      :class="{
                        'selected-option': answers[question.key] === option,
                        'answered-option': answers[question.key],
                        'non-answered-option': !answers[question.key]
                      }"
                      class="answer-button yn-button">
                {{ option }}
              </button>
            </div>

            <!-- Free text input (characters) -->
            <div v-else-if="question.type === 'freeTextChars' && shouldShow(question)" class="input-container">
              <textarea v-model="answers[question.key]" @input="handleAnswerChange(question.key, $event.target.value)" class="text-input" rows="2"></textarea>
              <label v-if="!answers[question.key]" class="floating-label">{{ question.question }}</label>
            </div>
          </div>

          <!-- Navigation buttons -->
          <div class="navigation-buttons" v-if="isMobile">
            <button @click="goBack" class="back-button" :disabled="currentPage <= 0">← Back</button>
            <button @click="nextPage" class="next-button">Next →</button>
          </div>

          <div class="navigation-buttons">
            <button @click="saveAndContinue" class="next-button">Save and Continue</button>
        </div>
        </div>
        <div v-else>
          <h2>Quiz Complete</h2>
        </div>

      </div>
    </div>
  </div>
</template>


<script>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import { db } from '../firebase/config';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import getUser from '@/composables/getUser';
import { q5, q5_title } from '@/data/quizQuestions';
import '/src/assets/css/styles.css';

export default {
  setup() {
    const router = useRouter();
    const { user } = getUser();
    const userId = user.value.uid;
    const quizId = 'q5';

    const questions = q5;
    const quizTitle = q5_title.value;

    const answers = ref({
      attachPersonalStatement: '',
      secret: '',
      funeralWear: '',
      birthdayCelebration: '',
      playlist: '',
      remembered: ''
    });

    const currentPage = ref(0);
    const windowWidth = ref(window.innerWidth);
    const isMobile = computed(() => windowWidth.value < 768);
    const questionsPerPage = computed(() => isMobile.value ? 1 : questions.value.length);
    const totalPages = computed(() => Math.ceil(questions.value.length / questionsPerPage.value));
    const displayedQuestions = computed(() => {
      const start = currentPage.value * questionsPerPage.value;
      const end = start + questionsPerPage.value;
      return questions.value.slice(start, end).filter(shouldShow);
    });
    const getQuestionNumber = index => currentPage.value * questionsPerPage.value + index + 1;

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    const handleAnswerChange = (questionKey, value) => {
      answers.value[questionKey] = value;
      const docRef = doc(db, `userAnswers/${userId}/${quizId}/data`);
      setDoc(docRef, { [questionKey]: value }, { merge: true })
        .then(() => console.log(`Updated Firestore with key: ${questionKey} and value: ${value}`))
        .catch(error => console.error("Failed to update Firestore:", error));
    };

    const saveAndContinue = () => {
      console.log('Answers:', answers.value);
      router.push('/MyProfile'); // Adjust as necessary to match your app's routing
    };

    const fetchAnswers = async () => {
      try {
        const docRef = doc(db, `userAnswers/${userId}/${quizId}/data`);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          Object.assign(answers.value, docSnap.data());
        }
      } catch (error) {
        console.error("Error fetching answers from Firestore:", error);
      }
    };

    const shouldShow = (question) => {
      if (!question.condition) return true;
      const { key, value } = question.condition;
      return answers.value[key] === value;
    };

    const goBack = () => {
      if (currentPage.value > 0) currentPage.value--;
    };

    const nextPage = () => {
      if (currentPage.value < totalPages.value - 1) {
        currentPage.value++;
      } else {
        saveAndContinue();
      }
    };

    const desktopGoBack = () => {
      router.push('/');
    };

    onMounted(() => {
      window.addEventListener('resize', handleResize);
      fetchAnswers();
    });

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize);
    });

    return {
      questions, answers, currentPage, totalPages, displayedQuestions, isMobile, getQuestionNumber, goBack, nextPage, handleAnswerChange, desktopGoBack, saveAndContinue, shouldShow
    };
  }
}
</script>
