<template>    
<DarkModeToggle />

  <div class="content-container">
    <header>
      <h2 class="custom-header">Welcome {{ userName || 'User' }}, to your online will writing service.</h2>
    </header>

    <div class="personal-info">
      <table>
        <tbody>
          <tr>
            <td><i class="fas fa-user icon"></i> Full Name:</td>
            <td>{{ personalInfo.fullName || 'N/A' }}</td>
            <td><i class="fas fa-birthday-cake icon"></i> Date of Birth:</td>
            <td>{{ personalInfo.dob || 'N/A' }}</td>
          </tr>
          <tr>
            <td><i class="fas fa-home icon"></i> Address:</td>
            <td>{{ personalInfo.address || 'N/A' }}</td>
            <td><i class="fas fa-phone icon"></i> Phone Number:</td>
            <td>{{ personalInfo.phoneNumber || 'N/A' }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <hr>

    <div class="documents-section">
      <h3>Documents</h3>
      <table>
        <thead>
          <tr>
            <th>Document Name</th>
            <th>Progress</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="allSections.length > 0">
            <tr v-for="section in allSections" :key="section.title">
              <td>{{ section.title }}</td>
              <td>
                <i :class="getStatusIconClass(section.status)" class="status-icon"></i>
                <span>{{ getStatusText(section.status) }}</span>
              </td>
              <td @click="navigateToSection(section.route)">
                <i :class="section.status === 'completed' ? 'fas fa-edit action-icon' : 'fas fa-play action-icon'"></i>
                <span class="action-text">{{ section.status === 'completed' ? 'Edit' : 'Start' }}</span>
              </td>
            </tr>
          </template>
          <tr v-else>
            <td colspan="3">Loading...</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import { db } from '../firebase/config';
import { collection, getDocs } from 'firebase/firestore';
import getUser from '@/composables/getUser';
import '/src/assets/css/styles.css';
import DarkModeToggle from './DarkModeToggle.vue';

export default {
  components: {
    DarkModeToggle,
  },
  setup() {
    const router = useRouter();
    const { user } = getUser();
    const userName = ref('');
    const personalInfo = ref({
      fullName: '',
      dob: '',
      address: '',
      phoneNumber: ''
    });
    const allSections = ref([]);

    const fetchSections = async () => {
      console.log('Fetching sections...');
      const userId = user.value?.uid;
      if (userId) {
        try {
          console.log('User ID:', userId);
          const userRef = collection(db, `userAnswers/${userId}/q1_1`);
          const userDocSnap = await getDocs(userRef);

          userDocSnap.forEach(doc => {
            const data = doc.data();
            console.log(`Fetched data for ${doc.id}:`, data);
            switch (doc.id) {
              case 'fullName':
                userName.value = data.answer || 'N/A';
                personalInfo.value.fullName = data.answer || 'N/A';
                break;
              case 'dob':
                personalInfo.value.dob = data.answer || 'N/A';
                break;
              case 'address':
                personalInfo.value.address = data.answer || 'N/A';
                break;
              case 'phoneNumber':
                personalInfo.value.phoneNumber = data.answer || 'N/A';
                break;
              default:
                break;
            }
          });

          const sectionsData = [
            {
              title: "About You",
              description: "Tell us a bit about yourself",
              quizId: "q1_1",
              route: "/AboutYou",
            },
            {
              title: "Your Family",
              description: "Add family members",
              quizId: "q1_2",
              route: "/AboutFamily",
            },
            {
              title: "Your Financial Assets",
              description: "What you own and how to get at it",
              quizId: "q2",
              route: "/Assets",
            },
            {
              title: "Executors",
              description: "Choose your executors",
              quizId: "q3",
              route: "/executors",
            },
            {
              title: "Your Funeral Wishes",
              description: "Preferences for your funeral",
              quizId: "q4",
              route: "/wishes-and-gifts",
            },
            {
              title: "Personal Statement",
              description: "Any additional thoughts",
              quizId: "q5",
              route: "/personal-statement",
            }
          ];

          for (let section of sectionsData) {
            const answersRef = collection(db, `userAnswers/${userId}/${section.quizId}`);
            const querySnapshot = await getDocs(answersRef);
            const filledAnswers = querySnapshot.docs.length;

            section.completedAnswers = filledAnswers;
            section.expectedAnswers = 5; // Assuming each quiz has 5 questions for simplicity
            section.status = filledAnswers >= section.expectedAnswers ? 'completed' :
                             (filledAnswers > 0 ? 'in progress' : 'not started');
          }

          allSections.value = sectionsData;
          console.log('Sections data updated:', sectionsData);

        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      } else {
        console.log('No user ID available');
      }
    };

    watch(user, async (newUser) => {
      console.log('User changed:', newUser);
      if (newUser) {
        await nextTick(); // Wait for the DOM updates to be flushed
        await fetchSections();
      }
    });

    onMounted(async () => {
      console.log('Component mounted, checking user...');
      if (user.value) {
        await nextTick(); // Wait for the DOM updates to be flushed
        await fetchSections();
      }
    });

    const navigateToSection = (route) => {
      router.push(route);
    };

    return {
      allSections,
      userName,
      personalInfo,
      fetchSections,
      navigateToSection,
      user // Return user to make it accessible
    };
  },
  methods: {
    getStatusIconClass(status) {
      switch (status) {
        case 'not started':
          return 'fas fa-circle status-not-started';
        case 'in progress':
          return 'fas fa-tasks status-in-progress';
        case 'completed':
          return 'fas fa-check-circle status-completed';
        default:
          return 'fas fa-circle status-not-started';
      }
    },
    getStatusText(status) {
      switch (status) {
        case 'not started':
          return 'Not Started';
        case 'in progress':
          return 'In Progress';
        case 'completed':
          return 'Completed';
        default:
          return 'Not Started';
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      console.log('Entering route, checking user...');
      if (vm.user.value) {
        await vm.$nextTick(); // Wait for the DOM updates to be flushed
        await vm.fetchSections();
      } else {
        const unwatch = vm.$watch('user', async (newUser) => {
          if (newUser) {
            await vm.$nextTick(); // Wait for the DOM updates to be flushed
            await vm.fetchSections();
            unwatch();
          }
        });
      }
    });
  },
  beforeRouteUpdate(to, from, next) {
    next(async (vm) => {
      console.log('Updating route, checking user...');
      if (vm.user.value) {
        await vm.$nextTick(); // Wait for the DOM updates to be flushed
        await vm.fetchSections();
      } else {
        const unwatch = vm.$watch('user', async (newUser) => {
          if (newUser) {
            await vm.$nextTick(); // Wait for the DOM updates to be flushed
            await vm.fetchSections();
            unwatch();
          }
        });
      }
    });
  }
}
</script>



<style scoped>
@import '@fortawesome/fontawesome-free/css/all.css';

.content-container {
  padding: 20px;
  font-family: 'Nunito Sans', sans-serif;
}

header {
  margin-bottom: 20px;
  text-align: center;
}

.personal-info {
  margin-bottom: 20px;
}

.personal-info table {
  width: 100%;
  border-collapse: collapse;
}

.personal-info td {
  padding: 10px;
  text-align: left;
  font-size: 12px;
}

.icon {
  color: #007891;
  margin-right: 5px;
}

hr {
  margin: 20px 0;
}

.documents-section {
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

thead th {
  border-bottom: 2px solid #000;
  padding: 10px;
  text-align: left;
  font-size: 12px;
}

tbody td {
  border-bottom: 1px solid #ccc;
  padding: 10px;
  text-align: left;
  font-size: 12px;
}

.profile-button {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.profile-button:hover {
  background-color: #0056b3;
}

.action-icon {
  cursor: pointer;
  font-size: 1.2em;
  color: #007891;
  margin-right: 5px;
  font-size: 1em; /* Adjust this value to make the icons smaller */
}

.action-icon:hover {
  color: #0056b3;
}

.action-text {
  cursor: pointer;
  font-size: 1em;
  color: #007891;
}

.status-icon {
  margin-right: 5px;
  font-size: 0.8em; /* Adjust this value to make the icons smaller */
}

.status-completed {
  color: #28a745;
}

.status-in-progress {
  color: #ffc107;
}

.status-not-started {
  color: #dc3545;
}
</style>
