<template>
  <div class="home">

    <!-- Hero Section -->
    <section id="home" class="hero">
      <video ref="backgroundVideo" autoplay muted loop playsinline class="background-video">
        <source src="../assets/hero-bg.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div class="hero-text container">
        <h4>With Our Services</h4>
        <hr />
        <h2>
          Get peace<br />
          of mind
        </h2>
        <hr />
      </div>
    </section>

    <!-- Prepare for Future Section -->
    <section id="services" class="prepare-for-future">
      <div class="container">
        <h2>{{ $t('sections.prepareForFuture') }}</h2>
        <p>{{ $t('sections.prepareForFutureContent') }}</p>

        <div class="steps">
          <div v-if="currentStep >= 1" class="step" :class="{ visible: currentStep >= 1 }">
            <div class="step-icon">
              <span class="step-number">1</span>
            </div>
            <div class="step-content">
              <p>{{ $t('sections.step1') }}</p>
            </div>
          </div>

          <div v-if="currentStep >= 2" class="step" :class="{ visible: currentStep >= 2 }">
            <div class="step-icon">
              <span class="step-number">2</span>
            </div>
            <div class="step-content">
              <p>{{ $t('sections.step2') }}</p>
            </div>
          </div>

          <div v-if="currentStep >= 3" class="step" :class="{ visible: currentStep >= 3 }">
            <div class="step-icon">
              <span class="step-number">3</span>
            </div>
            <div class="step-content">
              <p>{{ $t('sections.step3') }}</p>
            </div>
          </div>
        </div>

        <button v-if="showCTAButton" class="cta-button" @click="handleCTAClick">{{ $t('sections.startNow') }}</button>
      </div>
    </section>

    <!-- Contact Us Section -->
    <section id="contact" class="contact-section">
      <div class="form-wrapper">
        <div class="form-container">
          <h2 class="form-title">{{ $t('contactUs.title') }}</h2>
          <form action="#" class="contact-form">
            <div class="form-group">
              <label for="firstName" class="form-label">{{ $t('contactUs.firstName') }}</label>
              <input type="text" id="firstName" class="form-input" required>
            </div>
            <div class="form-group">
              <label for="lastName" class="form-label">{{ $t('contactUs.lastName') }}</label>
              <input type="text" id="lastName" class="form-input" required>
            </div>
            <div class="form-group">
              <label for="phone" class="form-label">{{ $t('contactUs.phone') }}</label>
              <input type="text" id="phone" class="form-input">
            </div>
            <div class="form-group">
              <label for="generalInfo" class="form-label">{{ $t('contactUs.generalInfo') }}</label>
              <textarea id="generalInfo" rows="6" class="form-textarea"></textarea>
            </div>
            <div class="form-group">
              <div class="checkbox-container">
                <input id="newsletter" type="checkbox" class="form-checkbox">
                <label for="newsletter" class="checkbox-label">{{ $t('contactUs.newsletter') }}</label>
              </div>
            </div>
            <button type="submit" class="generic-button">{{ $t('contactUs.submit') }}</button>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { auth } from '../firebase/config'; // Ensure correct import of your Firebase config

export default {
  name: 'HomeView',
  data() {
    return {
      currentStep: 0,
      showCTAButton: false,
    };
  },
  mounted() {
    this.startStepAnimation();
  },
  methods: {
    startStepAnimation() {
      setTimeout(() => {
        this.currentStep = 1;
        this.triggerTextAnimation(1);
      }, 500);
      setTimeout(() => {
        this.currentStep = 2;
        this.triggerTextAnimation(2);
      }, 2000);
      setTimeout(() => {
        this.currentStep = 3;
        this.triggerTextAnimation(3);
        this.showCTAButton = true; // Show CTA button after all steps are shown
      }, 3500);
    },
    triggerTextAnimation(step) {
      const textElement = document.querySelector(`.step:nth-child(${step}) .step-content`);
      if (textElement) {
        textElement.style.animation = `fadeInText 1.5s ease-in-out forwards`;
      }
    },
    handleCTAClick() {
      const user = auth.currentUser;
      if (user && user.emailVerified) {
        this.$router.push({ name: 'profile' });
      } else {
        this.$router.push({ name: 'new-user' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.hero {
  height: 100vh;
  overflow: hidden;

  .background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
    transform: translate(-50%, -50%);
    object-fit: cover;
  }

  .hero-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-90%, -1%);
    z-index: 2;
    display: flex;
    flex-direction: column;
    color: #cadae4;
    text-align: left;

    h4 {
      text-transform: uppercase;
      font-size: 22px;
      padding-bottom: 4px;
    }

    h2 {
      font-size: 50px;
      @media (min-width: 550px) {
        font-size: 80px;
      }
    }

    hr:nth-child(2) {
      max-width: 365px;
      margin-bottom: 16px;
    }
    hr:nth-child(4) {
      height: 6px;
      background-color: #fff;
      border: none;
      max-width: 85px;
      margin-top: 16px;
    }
  }
}

.prepare-for-future {
  padding: 50px 0;

  background-color: #f9f9f9;
  text-align: center;
padding-bottom: 50px;
  h2 {
    font-size: 36px;
    margin-bottom: 20px;
  }

  p {
    font-size: 18px;
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto;
  }

  .steps {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .step {
      opacity: 0;
      transform: translateY(50px);
      transition: opacity 1.2s ease, transform 1.2s ease;
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      width: 100%;
      max-width: 600px;

      &.visible {
        opacity: 1;
        transform: translateY(0);
      }

      .step-icon {
        width: 60px;
        height: 60px;
        background-color: #009ab3;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-right: 20px;
        font-size: 24px;
        flex-shrink: 0;

        .step-number {
          font-size: 24px;
          font-weight: bold;
          color: #fff;
        }
      }

      .step-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        animation: fadeInText 1.5s ease-in-out forwards;
        opacity: 0;
        transform: translateY(20px);
        transition: opacity 1.2s ease, transform 1.2s ease;

        &.visible {
          opacity: 1;
          transform: translateY(0);
        }

        p {
          font-size: 18px;
          color: #333;
          margin: 0;
        }
      }
    }
  }

  .cta-button {
    padding: 10px 20px;
    font-size: 14px;
    background-color: #009ab3;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;

    &:hover {
      background-color: #007a8c;
    }
  }

  /* Animation for text reveal */
  @keyframes fadeInText {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.contact-section {
  background: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url('../assets/contact_us.jpg');
  background-size: cover;
  background-position: center;
  padding: 50px 0;
  width: 100%;
  min-height: 100vh;

  .form-wrapper {
    padding: 2rem;
    max-width: 800px;
    margin: 0 auto;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 8px;
  }

  .form-container {
    padding: 2rem;
    border-radius: 8px;
  }

  .form-title {
    font-size: 2rem;
    font-weight: bold;
    text-align: left;
    margin-bottom: 1.5rem;
    color: #fff;
  }

  .contact-form {
    display: flex;
    flex-direction: column;
  }

  .form-group {
    margin-bottom: 1.5rem;

    .form-label {
      display: block;
      font-size: 1rem;
      font-weight: medium;
      color: #fff;
      margin-bottom: 0.5rem;
      text-align: left;
    }

    .form-input,
    .form-textarea {
      width: 100%;
      padding: 0.75rem;
      font-size: 1rem;
      border: 1px solid #ccc;
      border-radius: 0.375rem;
      background-color: #f9f9f9;
      color: #333;
      outline: none;
      transition: border-color 0.2s;

      &:focus {
        border-color: #009ab3;
      }
    }

    .form-textarea {
      resize: vertical;
    }
  }

  .checkbox-container {
    display: flex;
    align-items: center;

    .form-checkbox {
      width: 1rem;
      height: 1rem;
      border: 1px solid #ccc;
      border-radius: 0.25rem;
      margin-right: 0.5rem;
      cursor: pointer;

      &:checked {
        background-color: #009ab3;
        border-color: #009ab3;
      }
    }

    .checkbox-label {
      font-size: 1rem;
      color: #fff;
    }
  }

  .generic-button {
    padding: 10px 20px;
    font-size: 14px;
    background-color: #009ab3;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;

    &:hover {
      background-color: #007a8c;
    }
  }
}
</style>
