<template>
  <div :class="{ 'dark-mode': isDarkMode }">
    <NavigationView />
    <main class="main-content">
      <router-view/>
    </main>
    <GdprConsent />
    <FooterView />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import NavigationView from "./components/NavigationView";
import GdprConsent from './views/GdprConsent.vue';
import FooterView from './components/FooterView.vue';

export default {
  components: {
    NavigationView,
    GdprConsent,
    FooterView
  },
  computed: {
    ...mapGetters('darkMode', ['isDarkMode']),
  },
  mounted() {
    this.loadTheme();
  },
  methods: {
    ...mapActions('darkMode', ['loadTheme']),
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.main-content {
  padding-top: 80px; /* Match this value with the height of your navigation */
}

nav {
  padding: 20px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

/* Global light mode styles */
body {
  background-color: #f0f0f0;
  color: #000000;
}

/* Global dark mode styles */
.dark-mode body {
  background-color: #121212;
  color: #ffffff;
}

/* Additional global styles for dark mode */
.dark-mode .quiz-form {
  background-color: #333333;
  color: #ffffff;
}

.dark-mode .question-block {
  background-color: #444444;
  color: #ffffff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.dark-mode .nav-button {
  background-color: #007a8c;
}

.dark-mode .nav-button:hover {
  background-color: #005f6e;
}

.dark-mode input,
.dark-mode textarea,
.dark-mode select {
  background-color: #444444;
  color: #f2f2f2;
  border: 1px solid #555555;
}

.dark-mode input::placeholder,
.dark-mode textarea::placeholder {
  color: #bbbbbb;
}
</style>
