<template>
  <div class="container">
    <form @submit.prevent="handleLogin">
      <div class="title">Login</div>
      <div v-if="error" class="error">{{ error }}</div> <!-- Display login errors -->
      <div class="input-box underline">
        <input type="text" placeholder="Enter Your Email" v-model="email" required>
        <div class="underline"></div>
      </div>
      <div class="input-box">
        <input type="password" placeholder="Enter Your Password" v-model="password" required>
        <div class="underline"></div>
      </div>
      <div class="input-box-login button">
        <input type="submit" value="Login">
      </div>
    </form>
    <div class="option">or Connect With </div>
    <div class="social-buttons">
      <a href="#" class="google"><i class="fab fa-google"></i> Sign in with Google</a>
    </div>
    <br>

    <p>You do not have an account? <router-link to="/signup">Register here</router-link>.</p>
  </div>
  <p v-if="error" class="error-message">{{ error }}</p>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import useLogin from '../composables/useLogin.js';  // Check this path

export default {
  setup() {
    const router = useRouter();
    const { login, error } = useLogin();
    const email = ref('');
    const password = ref('');

    const handleLogin = async () => {
      await login(email.value, password.value, router);  // Pass the router here
    };

    return {
      email,
      password,
      error,
      handleLogin
    };
  }
}
</script>


<style scoped>
.container {
  background: #fff;
  margin-top: 160px;
  margin-bottom: 160px;
  max-width: 500px;
  width: 100%;
  padding: 25px 30px;
  border-radius: 10px;
  
  box-shadow: 5px 5px  10px 10px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.container form .title {
  font-size: 30px;
  font-weight: 600;
  margin: 10px 0 10px 0;
  position: relative;
}

.container form .title:before {
  content: '';
  position: absolute;
  height: 4px;
  width: 33px;
  left: 0px;
  bottom: 3px;
  border-radius: 10px;
  background: linear-gradient(to right, #009AB3 0%, #a5e2eb 100%);
}

.container form .input-box {
  width: 100%; 
  height: 45px;
  margin-top: 20px;
  position: relative;
  align-content: center;
}

.container form .input-box input {
  width: 100%; /* Make sure the input takes the full width of its container */
  height: 100%;
  outline: none;
  font-size: 16px;
  border: none;
}

.container form .underline::before {
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  background: #ccc;
  left: 0;
  bottom: 0;
}

.container form .underline::after {
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  background: linear-gradient(to right, #009AB3 0%, #8adae6 100%);
  left: 0;
  bottom: 0;
  transform: scaleX(0);
  transform-origin: left;
  transition: all 0.3s ease;
}

.container form .input-box input:focus ~ .underline::after,
.container form .input-box input:valid ~ .underline::after {
  transform: scaleX(1);
  transform-origin: left;
}

.container form .button {
  margin: 30px 0 20px 0;
}

.container .input-box-login {
  background: linear-gradient(to right, #009AB3 0%, #8adae6 100%);
  color: #fff;
  font-size: 17px;
  border-radius: 5px;
}

.container .input-box input[type="submit"] {
  background: linear-gradient(to right, #009AB3 0%, #8adae6 100%);
  font-size: 17px;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.container .input-box input[type="submit"]:hover {
  letter-spacing: 1px;
  background: linear-gradient(to left, #009AB3 0%, #8adae6 100%);
}

.container .option {
  font-size: 14px;
  text-align: center;
}

.google {
  display: block;
  background: linear-gradient(to right, #dd4b39 0%, #d14836 100%);
  color: white;
  font-size: 15px;
  text-decoration: none;
  padding: 12px 20px;
  margin-top: 20px;
  border-radius: 5px;
  text-align: center;
  transition: background-color 0.3s ease;
}

.google:hover {
  background: linear-gradient(to left, #dd4b39 0%, #d14836 100%);
}

.google i {
  margin-right: 10px;
}

.error {
  color: red;
  margin-bottom: 10px;
}


</style>