<template>
  <div class="panel panel-default">
    <div class="panel-heading">{{ q2_title }}</div>
    <div class="panel-body">
      <div class="question-container">
        <div v-if="questions.length > 0" class="question-frame">
          <div v-for="(asset, aIndex) in answers.assets" :key="'asset-' + aIndex" class="asset-block">
            <div class="asset-header">
              <h3>Asset {{ aIndex + 1 }}</h3>
              <button @click="removeAsset(aIndex)" class="delete-icon">
                <span class="material-icons">delete</span>
              </button>
            </div>
            <div v-for="(subQuestion, qIndex) in questions[0].subQuestions" :key="'subQuestion-' + qIndex">
              <p class="question-text">{{ subQuestion.question }}</p>
              <template v-if="subQuestion.type === 'dropdown'">
                <select v-model="asset[subQuestion.key]"
                      @change="handleAnswerChange(aIndex, subQuestion.key, $event.target.value)"
                      :class="{'answered-option': asset[subQuestion.key], 'non-answered-option': !asset[subQuestion.key]}"
                      class="dropdown">
                  <option disabled value="">Please select one</option>
                  <option v-for="option in subQuestion.options" :key="option" :value="option">{{ option }}</option>
                </select>
              </template>
              <template v-if="subQuestion.type === 'fileUpload'">
                <input type="file" @change="handleFileUpload(aIndex, subQuestion.key, $event)" class="file-input" multiple>
              </template>
              <template v-if="subQuestion.type === 'freeTextChars'">
                <div class="input-container">
                  <input class="text-input" type="text" v-model="asset[subQuestion.key]"
                    @input="handleAnswerChange(aIndex, subQuestion.key, $event.target.value)">
                  <label v-if="!asset[subQuestion.key]" class="input-label">Type Asset details</label>
                </div>
              </template>
              <template v-if="subQuestion.type === 'yn'">
                <div class="button-container yn">
                  <button v-for="option in ['Yes', 'No']" :key="option"
                          @click="handleAnswerChange(aIndex, subQuestion.key, option)"
                          :class="{
                            'selected-option': asset[subQuestion.key] === option,
                            'answered-option': asset[subQuestion.key],
                            'non-answered-option': !asset[subQuestion.key]
                          }"
                          class="answer-button yn-button">
                    {{ option }}
                  </button>
                </div>
              </template>
              <template v-if="subQuestion.type === 'multiple'">
                <div class="button-container multiple">
                  <button v-for="option in subQuestion.options" :key="option"
                          :class="{
                            'selected-option': asset[subQuestion.key] && asset[subQuestion.key].includes(option),
                            'answered-option': asset[subQuestion.key] && asset[subQuestion.key].includes(option),
                            'non-answered-option': !(asset[subQuestion.key] && asset[subQuestion.key].includes(option))
                          }"
                          @click="handleMultipleSelection(aIndex, subQuestion.key, option)"
                          class="answer-button multiple-choice-button">
                    {{ option }}
                  </button>
                </div>
              </template>
            </div>

            <!-- DIVIDE YOUR ESTATE Section -->
            <div class="divide-estate-section">
              <h4>Divide Your Estate</h4>
              <div v-for="(beneficiary, bIndex) in asset.beneficiaries || []" :key="'beneficiary-' + bIndex" class="beneficiary-block">
                <div class="input-container">
                  <input type="text" v-model="beneficiary.name" @input="handleBeneficiaryChange(aIndex, bIndex, 'name', $event.target.value)" class="text-input">
                  <label v-if="!beneficiary.name" class="input-label">Full Name</label>
                </div>
                <div class="input-container">
                  <select v-model="beneficiary.relation" @change="handleBeneficiaryChange(aIndex, bIndex, 'relation', $event.target.value)" class="dropdown">
                    <option disabled value="">Please select one</option>
                    <option v-for="relation in ['Child', 'Spouse', 'Parent', 'Sibling', 'Other']" :key="relation" :value="relation">{{ relation }}</option>
                  </select>
                </div>
                <button @click="removeBeneficiary(aIndex, bIndex)" class="delete-icon">
                  <span class="material-icons">delete</span>
                </button>
              </div>
              <button @click="addBeneficiary(aIndex)" class="new-add-button">
                <span class="material-icons">add</span> Add Beneficiary
              </button>
            </div>

            <!-- SPLIT YOUR ESTATE Section -->
            <div class="split-estate-section">
              <h4>Split Your Estate</h4>
              <p>If the beneficiary dies before you, who would you like to inherit the share you have left them?</p>
              <div class="button-container yn">
                <button v-for="option in ['Their children', 'Split between the following...']" :key="option"
                        @click="handleAnswerChange(aIndex, 'splitEstate', option)"
                        :class="{
                          'selected-option': asset.splitEstate === option,
                          'answered-option': asset.splitEstate,
                          'non-answered-option': !asset.splitEstate
                        }"
                        class="answer-button yn-button">
                  {{ option }}
                </button>
              </div>
              <div v-if="asset.splitEstate === 'Split between the following...'">
                <div v-for="(alternate, altIndex) in asset.alternateBeneficiaries || []" :key="'alternate-' + altIndex" class="beneficiary-block">
                  <div class="input-container">
                    <input type="text" v-model="alternate.name" @input="handleAlternateBeneficiaryChange(aIndex, altIndex, 'name', $event.target.value)" class="text-input">
                    <label v-if="!alternate.name" class="input-label">Full Name</label>
                  </div>
                  <div class="input-container">
                    <select v-model="alternate.relation" @change="handleAlternateBeneficiaryChange(aIndex, altIndex, 'relation', $event.target.value)" class="dropdown">
                      <option disabled value="">Please select one</option>
                      <option v-for="relation in ['Child', 'Spouse', 'Parent', 'Sibling', 'Other']" :key="relation" :value="relation">{{ relation }}</option>
                    </select>
                  </div>
                  <button @click="removeAlternateBeneficiary(aIndex, altIndex)" class="delete-icon">
                    <span class="material-icons">delete</span>
                  </button>
                </div>
                <button @click="addAlternateBeneficiary(aIndex)" class="new-add-button">
                  <span class="material-icons">add</span> Add Alternate Beneficiary
                </button>
              </div>
            </div>
          </div>
          <div class="add-asset-button-container">
            <p class="add-button-text material-text">Please click the button to add a new asset</p>
            <button @click="addAsset" class="new-add-button">
              <span class="material-icons">add</span> Add Asset
            </button>
          </div>
          <div v-if="!isMobile" class="navigation-buttons">
            <button @click="desktopGoBack" class="next-button">Save and Continue</button>
          </div>
        </div>
        <div v-else>
          <h2>Quiz Complete</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import { db } from '../firebase/config';
import { doc, setDoc, getDoc, updateDoc, deleteDoc, query, getDocs, collection } from 'firebase/firestore';
import getUser from '@/composables/getUser';
import { q2, q2_title } from '@/data/quizQuestions';
import '/src/assets/css/styles.css';

export default {
  setup() {
    const router = useRouter();
    const { user } = getUser();
    const userId = user.value.uid;
    const quizId = 'q2';

    const questions = q2;
    const q2Title = q2_title;

    const answers = ref({
      assets: []
    });

    const currentPage = ref(0);
    const windowWidth = ref(window.innerWidth);
    const isMobile = computed(() => windowWidth.value < 768);
    const questionsPerPage = computed(() => isMobile.value ? 1 : questions.value.length);
    const totalPages = computed(() => Math.ceil(questions.value.length / questionsPerPage.value));
    const displayedQuestions = computed(() => {
      const start = currentPage.value * questionsPerPage.value;
      const end = start + questionsPerPage.value;
      return questions.value.slice(start, end);
    });

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    const nextPage = () => {
      if (currentPage.value < totalPages.value - 1) {
        currentPage.value++;
      } else {
        submitQuiz();
      }
    };

    const goBack = () => {
      if (currentPage.value > 0) currentPage.value--;
    };

    const removeAsset = async (assetIndex) => {
      const assetId = answers.value.assets[assetIndex].id;
      if (assetId) {
        try {
          await deleteDoc(doc(db, `userAnswers/${userId}/${quizId}/${assetId}`));
          console.log(`Asset deleted: ${assetId}`);
          answers.value.assets.splice(assetIndex, 1);
        } catch (error) {
          console.error('Error deleting asset:', error);
        }
      } else {
        answers.value.assets.splice(assetIndex, 1);
      }
    };

    const handleAnswerChange = (assetIndex, subQuestionKey, value) => {
      if (!answers.value.assets[assetIndex]) {
        answers.value.assets[assetIndex] = {};
      }

      answers.value.assets[assetIndex][subQuestionKey] = value;

      console.log(`Answer updated: ${value}`, answers.value.assets[assetIndex]);

      const docRef = doc(db, `userAnswers/${userId}/${quizId}/${answers.value.assets[assetIndex].id || assetIndex}`);
      const updateObject = { [subQuestionKey]: value };
      setDoc(docRef, updateObject, { merge: true })
        .then(() => console.log('Firestore successfully updated'))
        .catch(error => console.error('Error updating Firestore:', error));
    };

    const handleMultipleSelection = (assetIndex, subQuestionKey, option) => {
      const asset = answers.value.assets[assetIndex];

      if (!asset[subQuestionKey]) {
        asset[subQuestionKey] = [];
      }

      const currentIndex = asset[subQuestionKey].indexOf(option);
      if (currentIndex === -1) {
        asset[subQuestionKey].push(option);
      } else {
        asset[subQuestionKey].splice(currentIndex, 1);
      }

      const docRef = doc(db, `userAnswers/${userId}/${quizId}/${answers.value.assets[assetIndex].id || assetIndex}`);
      const updateData = { [subQuestionKey]: asset[subQuestionKey] };
      setDoc(docRef, updateData, { merge: true })
        .then(() => console.log(`Updated Firestore for multiple selections at asset index ${assetIndex}`))
        .catch(console.error);
    };

    const addAsset = () => {
      let newAsset = {};
      questions.value[0].subQuestions.forEach(sq => {
        newAsset[sq.key] = sq.type === 'dropdown' || sq.type === 'multiple' ? '' : [];
      });
      answers.value.assets.push(newAsset);
    };

    const addBeneficiary = (assetIndex) => {
      if (!answers.value.assets[assetIndex].beneficiaries) {
        answers.value.assets[assetIndex].beneficiaries = [];
      }
      answers.value.assets[assetIndex].beneficiaries.push({ name: '', relation: '' });
    };

    const removeBeneficiary = (assetIndex, beneficiaryIndex) => {
      answers.value.assets[assetIndex].beneficiaries.splice(beneficiaryIndex, 1);
    };

    const handleBeneficiaryChange = (assetIndex, beneficiaryIndex, field, value) => {
      answers.value.assets[assetIndex].beneficiaries[beneficiaryIndex][field] = value;
    };

    const addAlternateBeneficiary = (assetIndex) => {
      if (!answers.value.assets[assetIndex].alternateBeneficiaries) {
        answers.value.assets[assetIndex].alternateBeneficiaries = [];
      }
      answers.value.assets[assetIndex].alternateBeneficiaries.push({ name: '', relation: '' });
    };

    const removeAlternateBeneficiary = (assetIndex, alternateIndex) => {
      answers.value.assets[assetIndex].alternateBeneficiaries.splice(alternateIndex, 1);
    };

    const handleAlternateBeneficiaryChange = (assetIndex, alternateIndex, field, value) => {
      answers.value.assets[assetIndex].alternateBeneficiaries[alternateIndex][field] = value;
    };

    const desktopGoBack = () => {
      router.push('/');
    };

    const handleFileUpload = (assetIndex, subQuestionKey, event) => {
      // Handle file uploads here
    };

    const submitQuiz = () => {
      console.log('Quiz submitted with answers:', answers.value);
      router.push('/MyProfile');
    };

    onMounted(() => {
      fetchAnswers();
      window.addEventListener('resize', handleResize);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize);
    });

    async function fetchAnswers() {
      try {
        const userDocPath = `userAnswers/${userId}/${quizId}`;
        const q = query(collection(db, userDocPath));
        const querySnapshot = await getDocs(q);
        const assets = [];
        querySnapshot.forEach((doc) => {
          const docData = doc.data();
          const formattedAsset = {
            id: doc.id,
            assetAddress: docData['assetAddress'],
            assetDetails: docData['assetDetails'],
            assetLiabilities: docData['assetLiabilities'],
            assetType: docData['assetType'],
            propertyOwnership: docData['propertyOwnership'],
            beneficiaries: docData['beneficiaries'] || [],
            alternateBeneficiaries: docData['alternateBeneficiaries'] || []
          };
          assets.push(formattedAsset);
        });

        answers.value.assets = assets;
      } catch (error) {
        console.error('Error fetching user answers:', error);
      }
    }

    return {
      answers,
      currentPage,
      displayedQuestions,
      handleAnswerChange,
      handleFileUpload,
      isMobile,
      nextPage,
      questions,
      totalPages,
      goBack,
      desktopGoBack,
      router,
      submitQuiz,
      addAsset,
      handleMultipleSelection,
      fetchAnswers,
      removeAsset,
      addBeneficiary,
      removeBeneficiary,
      handleBeneficiaryChange,
      addAlternateBeneficiary,
      removeAlternateBeneficiary,
      handleAlternateBeneficiaryChange,
      q2Title
    };
  }
}
</script>

<style scoped>


.material-icons {
  font-size: 24px;
  margin-right: 8px;
}

.add-asset-button-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}

.add-button-text {
  margin-bottom: 10px;
  font-size: 14px;
  color: #333;
}

.asset-block {
  margin-bottom: 20px;
}

.divide-estate-section, .split-estate-section {
  margin-top: 20px;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 5px;
}

.beneficiary-block {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.navigation-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}



.back-button:hover {
  background-color: #005f6b;
}

/* Other existing styles */
</style>
