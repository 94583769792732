// src\store\index.js

import { createStore } from 'vuex';
import { db } from '../firebase/config';
import { doc, setDoc, getDoc, collection, getDocs } from 'firebase/firestore';
const darkMode = {
  namespaced: true,
  state: {
    darkMode: false,
  },
  mutations: {
    SET_DARK_MODE(state, value) {
      state.darkMode = value;
      localStorage.setItem('theme', value ? 'dark' : 'light');
    },
  },
  actions: {
    loadTheme({ commit }) {
      const savedTheme = localStorage.getItem('theme');
      commit('SET_DARK_MODE', savedTheme === 'dark');
    },
    toggleTheme({ commit, state }) {
      commit('SET_DARK_MODE', !state.darkMode);
    },
  },
  getters: {
    isDarkMode: (state) => state.darkMode,
  },
};
const quizOne = {
  namespaced: true,
  state: {
    currentQuestionIndex: 0,
    answers: [],
    questions: [
      { question: "Do you have a partner?", options: ["No", "Yes", "It's Complicated"], type: 'yn' },
      { question: "Do you have children?", options: ["No", "Yes"], type: 'yn' },
      { question: "Do you have children from a previous marriage?", options: ["No", "Yes"], type: 'yn' },
      { question: "Do you own any property?", options: ["No", "Yes"], type: 'yn' },
      { question: "Are you a business owner or director/shareholder of a company?", options: ["No", "Yes"], type: 'yn' },
      { question: "Do you have a mortgage/debt or liability?", options: ["No", "Yes"], type: 'yn' },
      { question: "Do you live in Cyprus?", options: ["No", "Yes"], type: 'yn' },
      { question: "Is everything you own in Cyprus?", options: ["No", "Yes"], type: 'yn' },
      { question: "Where is your permanent residence?", options: [], type: 'countrySelect' },
    ],
    consultantNeeded: false
  },
  mutations: {
    SET_ANSWER(state, { index, answer }) {
      state.answers[index] = answer;
    },
    NEXT_QUESTION(state) {
      if (state.currentQuestionIndex < state.questions.length - 1) {
        state.currentQuestionIndex++;
      }
    },
    PREVIOUS_QUESTION(state) {
      if (state.currentQuestionIndex > 0) {
        state.currentQuestionIndex--;
      }
    },
    SET_CONSULTANT_NEEDED(state, needed) {
      state.consultantNeeded = needed;
    },
    INCREMENT_INDEX(state) {
      state.currentQuestionIndex++;
    },
    SET_COUNTRIES(state, countries) {
      const countryQuestion = state.questions.find(q => q.type === 'countrySelect');
      if (countryQuestion) {
        countryQuestion.options = countries;
      }
    }
  },
  actions: {
    answerQuestion({ commit, dispatch, state }, { answer }) {
      commit('SET_ANSWER', { index: state.currentQuestionIndex, answer });
      if (state.currentQuestionIndex < state.questions.length - 1) {
        commit('NEXT_QUESTION');
      } else {
        commit('INCREMENT_INDEX');
        dispatch('evaluateAnswers');
      }
    },
    evaluateAnswers({ commit, state }) {
      console.log("Evaluating answers...");
  
      let consultantNeeded = false;
      const { answers, questions } = state;
  
      if (answers.length < questions.length) {
        console.log("Not all questions answered yet.");
        return;
      }
  
      for (let i = 0; i < answers.length; i++) {
        const answer = answers[i];
        const question = questions[i]?.question;
  
        if (!answer || !question) continue;
  
        console.log(`Checking question: "${question}" with answer: "${answer}"`);
        
        if (
          (answer === 'Yes' && (question.includes('business owner') || question.includes('company'))) ||
          (answer === "It's Complicated" && question.includes('partner')) ||
          (answer === 'No' && question.includes('Cyprus')) ||
          (question === "Where is your permanent residence?" && answer !== "Cyprus")
        ) {
          console.log(`Condition met for question: "${question}" with answer: "${answer}"`);
          consultantNeeded = true;
          break;
        }
      }
  
      console.log("Consultation needed:", consultantNeeded);
      commit('SET_CONSULTANT_NEEDED', consultantNeeded);
    },
    goBack({ commit }) {
      commit('PREVIOUS_QUESTION');
    },
    async fetchCountries({ commit }) {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        const countries = await response.json();
        commit('SET_COUNTRIES', countries.map(country => country.name.common).sort());
      } catch (error) {
        console.error("Failed to fetch countries:", error);
      }
    }
  },
};


export default createStore({
  modules: {
    quizOne,
    darkMode,
  }
});
